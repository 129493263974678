import { useRef, useState } from 'react';
import { Alert, Box, Button, Typography } from '@mui/material';
import { useAuth } from './AuthProvider';
import { t } from '../../translations';
import GoogleAuth from './GoogleAuth';
import { z } from 'zod';
import { useForm } from '../../hooks/useForm';
import { getProp } from '../../helpers/utils';
import OrSeparator from './components/OrSeparator';
import MessageWithLink from './components/MessageWithLink';
import { UnauthorizedReason } from '../../types/auth';

const SigninSchema = z.object({
  email: z.string().min(1, t.Required).email(t.InvalidEmail),
  pass: z.string().min(1, t.Required),
});

type SigninData = z.infer<typeof SigninSchema>;

const SigninPage = () => {
  const { signin } = useAuth();

  const dataLoader = useRef(
    async (): Promise<SigninData> => ({
      email: '',
      pass: '',
    })
  ).current;

  const save = useRef(async (signinData: SigninData) => signin(signinData)).current;

  const [showResendEmailVerification, setShowResendEmailVerification] = useState(false);

  const onSaveFail = useRef(async (err) => {
    if (err?.response?.data?.reason === UnauthorizedReason.PendingEmailVerification) {
      setShowResendEmailVerification(true);
    }
  }).current;

  const { errorMessage, textInput, passwordInput, saveForm, editable, saving } = useForm({
    schema: SigninSchema,
    defaultSaveErrorMessage: t.SigninFailed,
    dataLoader,
    save,
    onSaveFail,
  });

  return (
    <Box>
      <Typography variant="h4" mb={4} textAlign="center" fontWeight="bold">
        {t.Signin}
      </Typography>

      <Box component="form" onSubmit={saveForm}>
        {textInput(getProp<SigninData>('email'), { label: t.Email, sx: { mb: 2 } })}
        {passwordInput(getProp<SigninData>('pass'), { label: t.Password, sx: { mb: 2 } })}

        {errorMessage && !saving && (
          <Alert sx={{ mb: 3 }} severity="error">
            {errorMessage}
          </Alert>
        )}

        <Button type="submit" variant="contained" disabled={!editable || saving} fullWidth size="large" sx={{ mb: 2 }}>
          {t.Signin}
        </Button>

        <MessageWithLink message={t.DontHaveAnAccount} anchorText={t.Signup} anchorLink="/auth/signup" />
        <MessageWithLink message={t.ForgotPassword} anchorText={t.RecoverPassword} anchorLink="/auth/pass-reset" />
        {showResendEmailVerification && (
          <MessageWithLink message={t.YourEmailHasNotBeenVerified} anchorText={t.ResendEmail} anchorLink="/auth/email-verification" />
        )}
      </Box>

      <OrSeparator />

      <Box sx={{ mb: 1 }}>
        <GoogleAuth />
      </Box>
    </Box>
  );
};

export default SigninPage;
