import { ReactNode } from 'react';
import { Gender } from '../types/auth';

export const en = {
  About: 'About',
  AboutBellitours: 'About Bellitours',
  Accept: 'Accept',
  AcceptCookies: 'Accept Cookies',
  AcceptedPaymentMethods: 'Accepted Payment Methods',
  AccommodationType: 'Accommodation Type',
  Account: 'Account',
  AccountManager: 'Account Manager',
  Actions: 'Actions',
  Active: 'Active',
  Activities: 'Activities',
  AddAnotherRoom: 'Add another Room',
  AddCoupon: 'Add Coupon',
  AddFilter: 'Add Filter',
  AddNewBanner: 'Add new banner',
  Address: 'Address',
  Admin: 'Admin',
  AdminLinks: 'Admin Links',
  adult: 'adult',
  adults: 'adults',
  Age: 'Age',
  Agent: 'Agent',
  AgentName: 'Agent Name',
  AllowNewBookings: 'Allow new bookings',
  AllowNewBookingsOutsideMoldova: 'Allow new bookings outside of Moldova',
  ProductionMode: 'Production mode',
  TestMode: 'Test mode',
  Amenities: 'Amenities',
  Amount: 'Amount',
  AmountToPay: 'Amount to Pay',
  and1Child: 'and 1 child',
  apartment: 'apartment',
  Apply: 'Apply',
  AreYouSureYouWantToRefundThisOrder: 'Are you sure you want to refund this order?',
  Avg: 'Avg',
  BackToHotelResults: 'Back to Hotel Results',
  Balance: 'Balance',
  banner: 'banner',
  Banners: 'Banners',
  BellitoursEstablishedIn2018takesPride:
    'Bellitours, established in 2018, takes pride in being a locally owned and operated company that caters to individual travel agents. We provide them with an opportunity to join our network, sell travel, and become valuable members of our dedicated team. While our primary focus is serving clients in the USA, we also extend our services to customers across Europe. We recognize that the travel industry is undergoing rapid transformations, and we attribute our success to the unwavering support we receive from our community, including you. In order to remain engaged with the US Southwest community, we actively participate in activities and events dedicated to enhancing the quality of life in our region. In response to the challenges posed by the pandemic in 2021, we made our system accessible and bookable online, allowing us to establish an online travel agency (OTA) that offers comprehensive travel booking services throughout the USA. This step was crucial in adapting to the changing landscape and meeting the needs of our customers in a convenient and accessible manner.',
  BillingAddress: 'Billing address',
  BillingCard: 'Billing card',
  BillingClient: 'Billing client',
  Book: 'Book',
  Booking: 'Booking',
  Bookings: 'Bookings',
  BookingAndPaymentReceiptsSentToEmail: 'The booking and payment receipts have been sent to your email.',
  BookingDetails: 'Booking Details',
  BookingReference: 'Booking Reference',
  BookingTermsConditions: 'Booking Terms & Conditions',
  ByEmail: 'By Email',
  CallUs: 'Call Us',
  Cancel: 'Cancel',
  CancellationPolicies: 'Cancellation Policies',
  CancellationPolicy: 'Cancellation Policy',
  Cancelled: 'Cancelled',
  Refunded: 'Refunded',
  CardNumber: 'Card number',
  Cars: 'Cars',
  Category: 'Category',
  CEO: 'CEO',
  Certificated: 'Certificated',
  Certifications: 'Certifications',
  ChargeType: 'Charge type',
  CheckAvailability: 'Check Availability',
  Checkin: 'Checkin',
  CheckIn: 'Checkin',
  CheckInAndCheckOutDatesRequired: 'Checkin and Checkout dates required',
  CheckinDate: 'Checkin Date',
  CheckOut: 'Check-out',
  CheckoutDate: 'Checkout Date',
  children: 'children',
  CityTax: 'City tax',
  Clear: 'Clear',
  ClientName: 'Client Name',
  ClientObservations: 'Client observations',
  Close: 'Close',
  ClosedPlaces: 'Closed Places',
  Code: 'Code',
  Company: 'Company',
  CompleteBooking: 'Complete Booking',
  ConfirmationNo: 'Confirmation No.',
  ConfirmBooking: 'Confirm Booking',
  Confirmed: 'Confirmed',
  ConfirmRefund: 'Confirm Refund',
  Contact: 'Contact',
  ContactSupport: 'Contact Support',
  CookiePolicy: 'Cookie Policy',
  CopyLink: 'Copy Link',
  CopyReferralCode: 'Copy Referral Code',
  Copyright2024ByBellitours: 'Copyright © 2024 by Bellitours',
  CountryIsRequired: 'Country is required',
  CouponApplied: 'Coupon applied',
  CouponCreated: 'Coupon created',
  CouponDiscount: 'Coupon discount',
  Coupons: 'Coupons',
  Coupon: 'Coupon',
  DiscountOff: 'off',
  Create: 'Create',
  CreateANewFee: 'Create a new Fee',
  IdAndCreatedAtDate: 'ID and Creation date',
  Created: 'Created',
  CreatedAt: 'Created at',
  CreateNew: 'Create new',
  CreateNewCoupon: 'Create new Coupon',
  CreditCard: 'Credit Card',
  CreditCardExp: 'Credit Card exp',
  CreditCardLast4: 'Credit Card last 4',
  Currency: 'Currency',
  CVC: 'CVC',
  Dashboard: 'Dashboard',
  Dates: 'Dates',
  Day: 'Day',
  days: 'days',
  Deals: 'Deals',
  Delete: 'Delete',
  Description: 'Description',
  DestinationType: 'Destination type',
  DestinationCode: 'Destination code',
  Destination: 'Destination',
  DestinationRequired: 'Destination required',
  Details: 'Details',
  DiscountAmount: 'Discount Amount',
  DiscountType: 'Discount Type',
  distance: 'distance',
  Done: 'Done',
  Download: 'Download',
  DoYouWantToDeletedTheFee: 'Do you want to deleted the fee?',
  DoYouWantToDeletedThisCoupon: 'Do you want to deleted this coupon?',
  DoYouWantToDeletedThisElement: 'Do you want to deleted this element?',
  driving: 'driving',
  DurationInDays: 'Duration in days',
  Edit: 'Edit',
  EditFee: 'Edit fee',
  email: 'email',
  Email: 'Email',
  EmailIsRequired: 'Email is required',
  EndAt: 'End at',
  EndDate: 'End Date',
  EnterTheAgesForTheChildren: 'Enter the ages for the children',
  EnterYourCoupon: 'Enter your coupon',
  Error: 'Error',
  Expense: 'Expense',
  Expiration: 'Expiration',
  ExploreEnjoy: 'Explore & Enjoy!',
  ExploreOurProperties: 'Explore our Properties',
  FailedCreatingCoupon: 'Failed creating coupon',
  FailedCreatingReferral: 'Failed creating referral',
  FailedToGetOrders: 'Failed to get orders',
  FailedToLoadTheCoupons: 'Failed to load the coupons',
  FailedToLoad: 'Failed to load',
  FailedToLoadTheFees: 'Failed to load the fees',
  FailedToLoadTheLogs: 'Failed to load the logs',
  FailedToLoadTheMessages: 'Failed to load the messages',
  FailedToLoadTheOrderReferrals: 'Failed to load the order referrals',
  FailedToLoadTheReferrals: 'Failed to load the referrals',
  FailedToRefund: 'Failed to refund',
  FailedUpdatingReferral: 'Failed updating referral',
  Fees: 'Fees',
  FeeType: 'Fee type',
  FilterBy: 'Filter By',
  Filters: 'Filters',
  FindAnotherDate: 'Find another date',
  FindAnotherDateInThisProperty: 'Find another date in this property',
  FirstName: 'First name',
  Fixed: 'Fixed',
  FollowUs: 'Follow Us',
  ForDirectAssistanceCallOurOfficeAt: ' For direct assistance call our office at',
  Found1Property: 'Found 1 property',
  FreeCancelation: 'Free Cancelation',
  from: 'from',
  FullName: 'Full name',
  FullNameIsTooLong: 'Full name is too long',
  FullNameIsTooShort: 'Full name is too short',
  Generate: 'Generate',
  GoodNewsPriceHasDecreasedMsg:
    '"Good news! The total price of your booking has decreased due to dynamic pricing. You will be charged a lower rate."',
  GoToTheHomePage: 'Go to the home page',
  GuaranteedPrices: 'Guaranteed prices',
  Guest: 'Guest',
  Guests: 'Guests',
  GuestsAndRooms: 'Guests and rooms',
  Hosts: 'Hosts',
  hotel: 'hotel',
  Hotel: 'Hotel',
  HotelCode: 'Hotel code',
  HotelsCode: 'Hotels code',
  HotelInfo: 'Hotel info',
  HotelObservations: 'Hotel observations',
  HotelRequest: 'Hotel Request',
  Hotels: 'Hotels',
  HowToGo: 'How to Go',
  IfYouReLookingAtAFamilyResort:
    "If you're looking at a family resort or a vacation package our consultants will guide you with their in-depth knowledge of places.",
  IfYouWereAssistedByAnAgentProvideTheirName: 'If you were assisted by an agent, please provide their name here.',
  IHaveACoupon: 'I have a coupon',
  Images: 'Images',
  ImportantInfo: 'Important Info',
  ImportantNotice: 'Important Notice',
  Inactive: 'Inactive',
  IndicatesRequired: 'Indicates required',
  Install: 'Install',
  Installing: 'Installing',
  InstallTheApp: 'Install the App',
  InternalReservationId: 'Internal Reservation Id',
  Invalid: 'Invalid',
  invalidCard: 'invalid card',
  InvalidEmail: 'Invalid email',
  InvalidPhoneNumber: 'Invalid phone number',
  IsAPromotionCoupon: 'Is a Promotion Coupon',
  KevinEmbarkedOnHisJourneyInTheTravelIndustry:
    '"Kevin embarked on his journey in the travel industry nearly twenty-five years ago. His professional trajectory commenced with a fortuitous encounter with the esteemed Italian worldwide vacation club, I Viaggi del Ventaglio. From humble beginnings as a travel guide and tourism entertainer, Kevin swiftly ascended the ranks, assuming pivotal roles such as team leader, trip management specialist, and group tourist developer within a remarkably short span of time. His exceptional ability to tackle challenges head-on, coupled with his innate talent for fostering strong connections with his clients, propelled him to stand out in his field. Above all, it was his unwavering passion for travel that ignited a spark within him, fuelling his dedication to this captivating industry."',
  Lang: 'Lang',
  Last7Days: 'Last 7 Days',
  LastMonth: 'Last Month',
  LastName: 'Last name',
  LastWeek: 'Last Week',
  Level: 'Level',
  Limit: 'Limit',
  Link: 'Link',
  listOfDaysOfTheWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  listOfMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
  LoadingPolicies: 'Loading Policies',
  LocallyOwned: 'Locally owned',
  Location: 'Location',
  Login: 'Login',
  Signin: 'Sign in',
  Signup: 'Sign up',
  LoginFailed: 'Login failed!',
  SigninFailed: 'Sign in failed!',
  SignupFailed: 'Signup failed!',
  Logout: 'Logout',
  Logs: 'Logs',
  LookingAvailabilitiesInTheSelectedDates: 'Looking availabilities in the selected dates',
  LowHotelRates: 'Low Hotel Rates',
  Manager: 'Manager',
  MarketingDirector: 'Marketing Director',
  Markup: 'Markup',
  Message: 'Message',
  Messages: 'Messages',
  MessageSent: 'Message sent!',
  MoreServices: 'More Services',
  Name: 'Name',
  NameLastName: 'Name & Last Name',
  NEED_HELP: 'NEED HELP?',
  nights: 'nights',
  Nights: 'Nights',
  nNAvailability: 'No availability',
  No: 'No',
  NoLocations: 'No locations',
  None: 'None',
  NoPropertiesFound: 'No properties found',
  NoReimbursable: 'No reimbursable',
  NoResults: 'No results',
  SearchingAvailabilitiesInTheSelectedWeek: 'Searching availabilities in the selected week',
  NothingToSeeHere: 'Nothing to see here!',
  NotVisible: 'Not visible',
  Ok: 'Ok',
  OnBehalfOf: 'On behalf of',
  OpenSettings: 'Open settings',
  Order: 'Order',
  OrderHaveBeenRefunded: 'Order have been refunded',
  OrderId: 'Order Id',
  OrderReferrals: 'Order Referrals',
  Orders: 'Orders',
  OurMission: 'Our Mission',
  OurServices: 'Our Services',
  OurStory: 'Our Story',
  OurUtmostGoalIsToMeticulouslyPlan:
    'Our utmost goal is to meticulously plan and craft the finest travel experience for you and your family, ensuring that we uncover the most exceptional travel options available. Additionally, we are committed to ensuring that every individual relishes their vacation with seamless ease. Hence, if you have any preferences or specific travel destinations in mind, or if you are commemorating a special occasion, kindly inform us! Whether you are an intrepid solo traveler, an adventurous couple, or a convivial group of family, friends, or coworkers, Bellitours is passionately dedicated to empowering you to explore the world and embrace boundless possibilities!',
  Paid: 'Paid',
  SetAsPaid: 'Mark as Paid',
  SetAsUnpaid: 'Mark as Unpaid',
  PartneredWithLeadingHotelChains:
    "Partnered with leading Hotel Chains and Rental Properties we offer one of the best Hotel's rates online. Just check our prices. You will be surprised",
  Password: 'Password',
  ConfirmPassword: 'Confirm password',
  Payed: 'Payed',
  Payload: 'Payload',
  PaymentDetails: 'Payment Details',
  PaymentInformation: 'Payment Information',
  PaymentType: 'Payment Type',
  Pending: 'Pending',
  PendingToPaid: 'Pending to Paid',
  PendingToPay: 'pending to pay',
  Percentage: 'Percentage',
  perNight: 'per night',
  Phone: 'Phone',
  PhoneNumber: 'Phone number',
  Photos: 'Photos',
  PleaseCompleteTheRequiredInformation: 'Please, complete the required information',
  Policies: 'Policies',
  Policy: 'Policy',
  PopularAmenities: 'Popular Amenities',
  PostalCode: 'Postal Code',
  PreReserved: 'Pre-reserved',
  Price: 'Price',
  PriceGreaterThan: 'Price greater than',
  PrivacyCookiesPolicy: 'Privacy & Cookies Policy',
  PrivacyStatement: 'Privacy Statement',
  ProceedToRefund: 'Proceed to Refund',
  Profile: 'Profile',
  Profit: 'Profit',
  Promotion: 'Promotion',
  PromotionCouponText: 'Promotion Coupon Text',
  PromotionText: 'Promotion Text',
  PropertyName: 'Property Name',
  PropertyPrice: 'Property Price',
  PropertyRating: 'Property Rating',
  PropertyType: 'Property Type',
  receipt: 'receipt',
  RecommendedAllInclusiveHotelsForYouToConsider: 'Recommended All Inclusive Hotels for you to consider',
  Referral: 'Referral',
  ReferralCode: 'Referral Code',
  ReferralCreated: 'Referral created',
  Referrals: 'Referrals',
  ReferralUpdated: 'Referral updated',
  ReferredBy: 'Referred by',
  ReferringAgent: 'Referring Agent',
  Refunding: 'Refunding',
  RefundOrder: 'Refund Order',
  Reimbursable: 'Reimbursable',
  Remove: 'Remove',
  RemoveCoupon: 'Remove Coupon',
  RemoveImage: 'Remove image',
  RemoveRoom: 'Remove Room',
  RequestToTheHotel: 'Request to the hotel',
  Required: 'Required',
  ReservationConfirmed: 'Reservation confirmed!',
  ReservationInfo: 'Reservation Info',
  ReservationNo: 'Reservation No.',
  Reservations: 'Reservations',
  ReservePrice: 'Reserve Price',
  Room: 'Room',
  rooms: 'rooms',
  SalesManager: 'Sales Manager',
  Save: 'Save',
  SaveOnYourNextStay: 'Save on your next stay',
  Saving: 'Saving',
  Search: 'Search',
  SearchALocation: 'Search a location',
  SearchADestinationsAndHotels: 'Search destinations and hotels',
  SearchDestinations: 'Search destinations',
  SearchHotels: 'Search hotels',
  searching: 'searching',
  Searching: 'Searching',
  SearchMore: 'Searching more',
  SearchMoreProperties: 'Search more properties',
  Select: 'Select',
  SelectACountry: 'Select a country',
  SelectDateAndTime: 'Select Date and Time',
  SelectGuests: 'Select guests',
  SellPrice: 'Sell Price',
  SendMessage: 'SendMessage',
  ServingYouSince2018: 'Serving you since 2018',
  Settings: 'Settings',
  ShowAll: 'Show all',
  ShowInMap: 'Show in Map',
  ShowLess: 'Show less',
  SorryAnUnexpectedErrorHasOccurred: 'Sorry, an unexpected error has occurred.',
  Sort: 'Sort',
  SortBy: 'Sort By',
  SpecialRequestForTheHotel: 'Special request for the Hotel',
  stars: 'stars',
  StartAt: 'Start at',
  StartDate: 'Start Date',
  StartPlanningWithUs: 'Start planning with us',
  Status: 'Status',
  StripeCharge: 'Stripe charge',
  StripeCurrency: 'Stripe currency',
  StripeCustomerId: 'Stripe Customer Id',
  StripeReceiptUrl: 'Stripe receipt url',
  StripeRefund: 'Stripe refund',
  StripeStatus: 'Stripe Status',
  Subject: 'Subject',
  Subscribe: 'Subscribe',
  Subtotal: 'Subtotal',
  Support: 'Support',
  tel: 'tel',
  TermsOfService: 'Terms of Service',
  Text: 'Text',
  TheCouponDoesNotApplyPleaseRemoveTheCouponBeforeProceed: 'The coupon does not apply, please remove the coupon before proceed',
  TheHotelHasProvidedTheFollowingImportantInfo:
    'The hotel has provided the following important information that you need to review and accept before completing your booking:',
  ThereIsNoAvailabilityInTheSelectedDates: 'There is no availability in the selected dates',
  ThereWasAnErrorSendingTheMessagePleaseTryAgain: 'There was an error sending the message. Please try again',
  ThereWasAnErrorWithYourCreditCard: 'There was an error with your credit card, please try again',
  ThisFieldIsRequired: 'This field is required',
  ThisMonth: 'This Month',
  ThisWebsiteUsesCookiesToEnhanceUserExperience:
    'This website uses cookies to enhance user experience and to analyze performance and traffic on our website. We also share information about your use if our site with our social media, advertising and analytics partners.',
  ThisWeek: 'This Week',
  Title: 'Title',
  Today: 'Today',
  TopHotels: 'Top hotels',
  total: 'total',
  Total: 'Total',
  TotalPayed: 'Total Payed',
  TotalPrice: 'Total price',
  Tours: 'Tours',
  ToursDestinations: 'Tours Destinations',
  TravelAdvisor: 'Travel Advisor',
  TravelAgent: 'Travel Agent',
  TravelInsurance: 'Travel Insurance',
  TrendingDestinations: 'Trending Destinations',
  Type: 'Type',
  TypeOfProfit: 'Type of profit',
  UnexpectedError: 'Unexpected Error',
  Unit: 'Unit',
  UnlockExclusiveTravelInspirationForYourNextGetawaySubscribeToOurNewsletter:
    'Unlock exclusive travel inspiration for your next getaway subscribe to our newsletter.',
  Unpaid: 'Unpaid',
  Unrated: 'Unrated',
  Unselect: 'Unselect',
  Update: 'Update',
  UpdatedAt: 'Updated at',
  UpsSomethingWasWrong: 'Ups. Something was wrong!',
  Used: 'Used',
  VacationPackages: 'Vacation Packages',
  Value: 'Value',
  View: 'View',
  viewLess: 'view less',
  viewMore: 'view more',
  Visible: 'Visible',
  walking: 'walking',
  WeAreContinuouslyDevelopingOurSystem:
    'We are continuously developing our system, our skills and travel knowledge to provide the best customer service.',
  Website: 'Website',
  WeDLoveToHearFromYou: "We'd love to hear from you.",
  WeHaveSpecialToursDestinationsAndPackages:
    'We have special tours destinations and packages ready for you. Tailored just for our clients, we create routes and special trips',
  WelcomeYouVeBeenReferredToOurWebsiteBy: '"Welcome" You\'ve been referred to our website by',
  WeLlGiveYouThePriceYouWillEnjoyTheTime: "We'll give you the price. You will enjoy the time",
  Yes: 'Yes',
  Yesterday: 'Yesterday',
  YouCanUseBellitoursDirectlyInYourMobileForABetterExperience: 'You can use Bellitours directly in your mobile for a better experience.',
  YourContactInfo: 'Your contact information',
  YourMessageHaveBeenSent: 'Your message have been sent!',
  YourPaymentInfo: 'Your payment information',
  Client: 'Client',
  Customer: 'Customer',
  Discount: 'Discount',
  orLoginWithEmailAndPassword: 'or sign in with email and password',
  BackToHome: 'Go to Home Page',
  ForgotPassword: 'Forgot password?',
  DontHaveAnAccount: 'Don\'t have an account?',
  RecoverPassword: 'Recover password',
  AlreadyHaveAnAccount: 'Already have an account?',
  PasswordsDonTMatch: 'Passwords don\'t match',
  ThankYouForSigningUp: 'Thank you for signing up',
  ToCompleteYourRegistrationCheckEmail: 'To complete your registration, please check your email for a verification link. Click the link to confirm your email address and activate your account. If you don\'t see the email in your inbox, please check your spam or junk folder',
  VerifyingEmail: 'Verifying email',
  InvalidLink: 'Invalid link',
  LinkExpired: 'Link expired',
  EmailValidatedSuccessfully: 'Email has been successfully validated',
  TheVerificationLinkYouUsedIsInvalid: 'We\'re sorry, but the verification link you used is invalid. Please check the link in your email and try again, or request a new verification link.',
  TheVerificationLinkHasExpired: 'The verification link has expired. Please request a new verification email to confirm your address.',
  YourAccountHasAlreadyBeenVerified: 'Your account has already been verified. You can now log in using your credentials.',
  YourEmailHasBeenSuccessfullyVerified: 'Thank you! Your email has been successfully verified. You can now log in to your account.',
  YourEmailHasNotBeenVerified: 'Your email has not been verified?',
  ResendEmail: 'Resend email',
  ResendVerificationEmail: 'Resend verification email',
  FailedToResendEmailPleaseTryAgain: 'Failed to resend email, please try again.',
  VerificationEmailHasBeenResend: 'Verification email has been resend',
  AVerificationEmailWillBeSendToYourEmailAddress: 'A verification email will be send to your email address if the email is still pending of verification. Please check your inbox (and your spam/junk folder) for the email, and click the link to verify your account.',
  EmailHasBeenSent: 'Email has been sent',
  EmailToRecoverPassHasBeenSend: 'An email to recover your password has been sent to the email address specified. Please check your inbox (and your spam/junk folder) for the email, and click the link to recover your password.',
  RecoverPass: 'Recover password',
  UpdateYourPassword: 'Update your password',
  PasswordSuccessfullyChanged: 'Password Successfully Changed',
  YourPasswordHasBeenUpdatedSuccessfully: 'Your password has been updated successfully. Please use your new password the next time you sign in. If you did not request this change, please contact our support team immediately.',
  UpdatePasswordFailed: 'Update password failed!',
  AnUnexpectedErrorOccurredWhileUpdatingPassword: 'An unexpected error occurred while attempting to update your password. Please try again later. If the issue persists, please contact our support team',
  ThePasswordResetLinkIsInValidOrExpired: 'Sorry, the password reset link is invalid or has expired. Please request a new password reset link and try again',
  PasswordUpdatedSuccessfully: 'Your password has been successfully updated. You can now sign in with your new password. If you did not request this change, please contact our support team immediately.',
  YouDontHaveAccessToTheRequestedPage: "You don't have permissions to access to the requested page",
  Role: 'Role',
  AuthProviders: 'Auth providers',
  Verified: 'Verified',
  Users: 'Users',
  User: 'User',
  Hi: 'Hi',
  Favorites: 'Favorites',
  ProvideYourPersonalDetails: 'Provide your personal details',
  CheckYourFavoriteProperties: 'Check your favorite properties',
  SeeYourReservations: 'See your reservations',
  Security: 'Security',
  CheckYouSecurityAndSettings: 'Check your security and settings',
  NotProvided: 'Not provided',
  Gender: 'Gender',
  Bio: 'Bio',
  DateOfBirth: 'Date of birth',
  ShowPricesBy: 'Show prices group by',
  SearchPricesBy: 'Search prices by range',
  ShowAdvancedSearch: 'Show flexible date options',
  HideAdvancedSearch: 'Hide flexible date options',
  YourFavoriteProperties: 'Your favorite properties',
  NoDiscount: 'No discount',
  Commission: 'Commission',
  GetGender: (gender: Gender) => ({
    [Gender.Female]: 'Female',
    [Gender.Male]: 'Male',
    [Gender.Unspecified]: 'Unspecified',
    [Gender.Undisclosed]: 'Undisclosed',
  }[gender]),
  RequireAtLeastNChars: (n: number) => `Require at least ${n} characters`,
  // -----
  acceptTerms: (props: { privacyStatementLink: ReactNode; termsOfServiceLink: ReactNode }) => (
    <>
      By clicking this button, I confirm I have read the {props.privacyStatementLink} and Government Travel Advice, and have read and accept
      the {props.termsOfServiceLink}.
    </>
  ),
  andNChildren: (amount: number) => `and ${amount} children`,
  AnErrorHasOccurred: (message) => `An error has occurred! ${message || ''}`,
  AnErrorHasOccurredTryAgain: (message) => `An error has occurred! ${message || 'Please, try again.'}`,
  AnUnexpectedErrorHasOccurredPleaseReloadThePage: (message) =>
    `An unexpected error has occurred. Please reload the page. ${message || ''}`,
  DoYouWantToActivateDeactivateCoupon: (props: { activate: boolean }) =>
    `Do you want to ${props.activate ? 'activate' : 'deactivate'} this coupon?`,
  DoYouWantToUpdateThisOrderReferralAsPaidUnpaid: (props: { paid: boolean }) =>
    `Do you want to update this order referral as ${props.paid ? 'paid' : 'unpaid'}`,
  foundNProperties: (amount: number) => `Found ${amount} properties`,
  goodNewsPriceHasDecreasedToNewTotalMsg: (total: number) => (
    <>
      The total price of your booking has increased due to dynamic pricing. The new total is <b>{total}</b>. You can choose to proceed with
      the updated amount or cancel your booking. Please confirm your choice to continue or cancel.
    </>
  ),
  hotelConsiderChildrenFrom: (props: { from: ReactNode; to: ReactNode }) => (
    <>
      Hotel consider children from {props.from} to {props.to} years old.
    </>
  ),
  MinLengthIs: (len) => `Min length is ${len}`,
  MinValueIs: (value) => `Min value is ${value}`,
  protectionOfPersonalInfoMsg: (
    <>
      We ensure the protection of your personal information through secure transmission and encrypted storage.
      <br /> While payments are generally processed in the U.S., certain travel providers (such as hotels or airlines) may process your
      payment outside the U.S., which could result in your card issuer applying a foreign transaction fee.
    </>
  ),
  selectNRoomForPax: (props: { room: any; pax: any }) => (
    <>
      Select {props.room} room for {props.pax}
    </>
  ),
  ValueNeedsToBeGreaterThanN: (number) => `Value needs to be greater than ${number}`,
};
