import { ReactNode } from 'react';
import { Gender } from '../types/auth';

export const it = {
  About: 'Chi siamo',
  AboutBellitours: 'Chi siamo - Bellitours',
  Accept: 'Accetta',
  AcceptCookies: 'Accetta i Cookies',
  AcceptedPaymentMethods: 'Metodi di Pagamento Accettati',
  AccommodationType: 'Tipo di Sistemazione',
  Account: 'Account',
  AccountManager: 'Responsabile dell’Account',
  Actions: 'Azioni',
  Active: 'Attivo',
  Activities: 'Attività',
  AddAnotherRoom: 'Aggiungi un’altra stanza',
  AddCoupon: 'Aggiungi Coupon',
  AddFilter: 'Aggiungi Filtro',
  AddNewBanner: 'Aggiungi nuovo banner',
  Address: 'Indirizzo',
  Admin: 'Amministratore',
  AdminLinks: 'Link Amministrativi',
  adult: 'adulto',
  adults: 'adulti',
  Age: 'Età',
  Agent: 'Agente',
  AgentName: 'Nome dell’Agente',
  AllowNewBookings: 'Consenti nuove prenotazioni',
  AllowNewBookingsOutsideMoldova: 'Consenti nuove prenotazioni al di fuori della Moldavia',
  ProductionMode: 'Modalità di produzione',
  TestMode: 'Modalità di prova',
  Amenities: 'Servizi',
  Amount: 'Importo',
  AmountToPay: 'Importo da Pagare',
  and1Child: 'e 1 bambino',
  apartment: 'appartamento',
  Apply: 'Applica',
  AreYouSureYouWantToRefundThisOrder: 'Sei sicuro di voler rimborsare questo ordine?',
  Avg: 'Media',
  BackToHotelResults: 'Torna ai risultati dell’hotel',
  Balance: 'Saldo',
  banner: 'banner',
  Banners: 'Banner',
  BellitoursEstablishedIn2018takesPride:
    'Bellitours, fondata nel 2018, è orgogliosa di essere un’azienda locale che si dedica agli agenti di viaggio individuali. Offriamo loro l’opportunità di unirsi alla nostra rete, vendere viaggi e diventare membri preziosi del nostro team dedicato. Sebbene il nostro obiettivo principale sia servire i clienti negli Stati Uniti, estendiamo anche i nostri servizi ai clienti in tutta Europa. Riconosciamo che l’industria dei viaggi sta attraversando rapidi cambiamenti e attribuiamo il nostro successo al supporto incrollabile che riceviamo dalla nostra comunità, compreso te. Per rimanere coinvolti con la comunità del Sud-Ovest degli Stati Uniti, partecipiamo attivamente ad attività ed eventi dedicati al miglioramento della qualità della vita nella nostra regione. In risposta alle sfide poste dalla pandemia nel 2021, abbiamo reso il nostro sistema accessibile e prenotabile online, permettendoci di stabilire un’agenzia di viaggi online (OTA) che offre servizi completi di prenotazione viaggi in tutto gli Stati Uniti. Questo passaggio è stato cruciale per adattarsi al paesaggio in evoluzione e soddisfare le esigenze dei nostri clienti in modo comodo e accessibile.',
  BillingAddress: 'Indirizzo di fatturazione',
  BillingCard: 'Carta di fatturazione',
  BillingClient: 'Cliente di fatturazione',
  Book: 'Prenota',
  Booking: 'Prenotazione',
  Bookings: 'Prenotazioni',
  BookingAndPaymentReceiptsSentToEmail: 'Le ricevute di prenotazione e pagamento sono state inviate alla tua email.',
  BookingDetails: 'Dettagli della Prenotazione',
  BookingReference: 'Riferimento Prenotazione',
  BookingTermsConditions: 'Termini e Condizioni della Prenotazione',
  ByEmail: 'Via Email',
  CallUs: 'Contattaci',
  Cancel: 'Annulla',
  CancellationPolicies: 'Politiche di Cancellazione',
  CancellationPolicy: 'Politica di Cancellazione',
  Cancelled: 'Annullato',
  Refunded: 'Rimborsato',
  CardNumber: 'Numero della carta',
  Cars: 'Auto',
  Category: 'Categoria',
  CEO: 'Amministratore Delegato',
  Certificated: 'Certificato',
  Certifications: 'Certificazioni',
  ChargeType: 'Tipo di addebito',
  CheckAvailability: 'Verifica Disponibilità',
  Checkin: 'Check-in',
  CheckIn: 'Check-in',
  CheckInAndCheckOutDatesRequired: 'Date di check-in e check-out obbligatorie',
  CheckinDate: 'Data di Check-in',
  CheckOut: 'Check-out',
  CheckoutDate: 'Data di Check-out',
  children: 'bambini',
  CityTax: 'Tassa di soggiorno',
  Clear: 'Pulisci',
  ClientName: 'Nome del Cliente',
  ClientObservations: 'Osservazioni del Cliente',
  Close: 'Chiudi',
  ClosedPlaces: 'Luoghi Chiusi',
  Code: 'Codice',
  Company: 'Azienda',
  CompleteBooking: 'Completa Prenotazione',
  ConfirmationNo: 'Numero di Conferma',
  ConfirmBooking: 'Conferma Prenotazione',
  Confirmed: 'Confermato',
  ConfirmRefund: 'Conferma Rimborso',
  Contact: 'Contatto',
  ContactSupport: 'Contatta il Supporto',
  CookiePolicy: 'Politica sui Cookie',
  CopyLink: 'Copia Link',
  CopyReferralCode: 'Copia Codice di Riferimento',
  Copyright2024ByBellitours: 'Copyright © 2024 by Bellitours',
  CountryIsRequired: 'Il paese è obbligatorio',
  CouponApplied: 'Coupon applicato',
  CouponCreated: 'Coupon creato',
  CouponDiscount: 'Sconto Coupon',
  Coupons: 'Coupon',
  Coupon: 'Coupon',
  DiscountOff: 'sconto',
  Create: 'Crea',
  CreateANewFee: 'Crea una nuova Tariffa',
  IdAndCreatedAtDate: 'ID e data di creazione',
  Created: 'Creato',
  CreatedAt: 'Creato il',
  CreateNew: 'Crea nuovo',
  CreateNewCoupon: 'Crea nuovo Coupon',
  CreditCard: 'Carta di Credito',
  CreditCardExp: 'Scadenza Carta di Credito',
  CreditCardLast4: 'Ultimi 4 numeri della Carta di Credito',
  Currency: 'Valuta',
  CVC: 'CVC',
  Dashboard: 'Cruscotto',
  Dates: 'Date',
  Day: 'Giorno',
  days: 'giorni',
  Deals: 'Offerte',
  Delete: 'Elimina',
  Description: 'Descrizione',
  DestinationType: 'Tipo di Destinazione',
  DestinationCode: 'Codice di Destinazione',
  Destination: 'Destinazione',
  DestinationRequired: 'Destinazione obbligatoria',
  Details: 'Dettagli',
  DiscountAmount: 'Importo Sconto',
  DiscountType: 'Tipo di Sconto',
  distance: 'distanza',
  Done: 'Fatto',
  Download: 'Scarica',
  DoYouWantToDeletedTheFee: 'Vuoi eliminare la tariffa?',
  DoYouWantToDeletedThisCoupon: 'Vuoi eliminare questo coupon?',
  DoYouWantToDeletedThisElement: 'Vuoi eliminare questo elemento?',
  driving: 'guida',
  DurationInDays: 'Durata in giorni',
  Edit: 'Modifica',
  EditFee: 'Modifica tariffa',
  email: 'email',
  Email: 'Email',
  EmailIsRequired: 'Email obbligatoria',
  EndAt: 'Fine il',
  EndDate: 'Data di Fine',
  EnterTheAgesForTheChildren: 'Inserisci le età dei bambini',
  EnterYourCoupon: 'Inserisci il tuo coupon',
  Error: 'Errore',
  Expense: 'Spesa',
  Expiration: 'Scadenza',
  ExploreEnjoy: 'Esplora & Divertiti!',
  ExploreOurProperties: 'Esplora le nostre Proprietà',
  FailedCreatingCoupon: 'Creazione del coupon fallita',
  FailedCreatingReferral: 'Creazione del referral fallita',
  FailedToGetOrders: 'Impossibile ottenere gli ordini',
  FailedToLoadTheCoupons: 'Impossibile caricare i coupon',
  FailedToLoad: 'Impossibile caricare',
  FailedToLoadTheFees: 'Impossibile caricare le tariffe',
  FailedToLoadTheLogs: 'Impossibile caricare i log',
  FailedToLoadTheMessages: 'Impossibile caricare i messaggi',
  FailedToLoadTheOrderReferrals: 'Impossibile caricare i referral degli ordini',
  FailedToLoadTheReferrals: 'Impossibile caricare i referral',
  FailedToRefund: 'Impossibile effettuare il rimborso',
  FailedUpdatingReferral: 'Aggiornamento del referral fallito',
  Fees: 'Tariffe',
  FeeType: 'Tipo di Tariffa',
  FilterBy: 'Filtra per',
  Filters: 'Filtri',
  FindAnotherDate: 'Trova un’altra data',
  FindAnotherDateInThisProperty: 'Trova un’altra data in questa proprietà',
  FirstName: 'Nome',
  Fixed: 'Fisso',
  FollowUs: 'Seguici',
  ForDirectAssistanceCallOurOfficeAt: 'Per assistenza diretta chiama il nostro ufficio al',
  Found1Property: 'Trovata 1 proprietà',
  FreeCancelation: 'Cancellazione Gratuita',
  from: 'da',
  FullName: 'Nome completo',
  FullNameIsTooLong: 'Il nome completo è troppo lungo',
  FullNameIsTooShort: 'Il nome completo è troppo corto',
  Generate: 'Genera',
  GoodNewsPriceHasDecreasedMsg:
    '"Buone notizie! Il prezzo totale della tua prenotazione è diminuito a causa dei prezzi dinamici. Ti verrà addebitato un importo inferiore."',
  GoToTheHomePage: 'Vai alla pagina principale',
  GuaranteedPrices: 'Prezzi Garantiti',
  Guest: 'Ospite',
  Guests: 'Ospiti',
  GuestsAndRooms: 'Ospiti e stanze',
  Hosts: 'Host',
  hotel: 'hotel',
  Hotel: 'Hotel',
  HotelCode: 'Codice dell’Hotel',
  HotelsCode: 'Codici degli Hotel',
  HotelInfo: 'Info Hotel',
  HotelObservations: 'Osservazioni dell’Hotel',
  HotelRequest: 'Richiesta Hotel',
  Hotels: 'Hotel',
  HowToGo: 'Come Arrivare',
  IfYouReLookingAtAFamilyResort:
    'Se stai cercando un resort per famiglie o un pacchetto vacanze, i nostri consulenti ti guideranno con la loro conoscenza approfondita dei luoghi.',
  IfYouWereAssistedByAnAgentProvideTheirName: 'Se sei stato assistito da un agente, per favore fornisci il loro nome qui.',
  IHaveACoupon: 'Ho un coupon',
  Images: 'Immagini',
  ImportantInfo: 'Informazioni Importanti',
  ImportantNotice: 'Avviso Importante',
  Inactive: 'Non Attivo',
  IndicatesRequired: 'Indica obbligatorio',
  Install: 'Installa',
  Installing: 'Installazione',
  InstallTheApp: 'Installa l\'App',
  InternalReservationId: 'ID Prenotazione Interno',
  Invalid: 'Non valido',
  invalidCard: 'Carta non valida',
  InvalidEmail: 'Email non valida',
  InvalidPhoneNumber: 'Numero di telefono non valido',
  IsAPromotionCoupon: 'È un Coupon Promozionale',
  KevinEmbarkedOnHisJourneyInTheTravelIndustry:
    '"Kevin ha intrapreso il suo percorso nel settore dei viaggi quasi venticinque anni fa. La sua carriera professionale è iniziata con un incontro fortuito con il rinomato club di vacanze italiano, I Viaggi del Ventaglio. Da umili origini come guida turistica e intrattenitore, Kevin è rapidamente salito di grado, assumendo ruoli chiave come leader di squadra, specialista nella gestione dei viaggi e sviluppatore di turisti di gruppo in un arco di tempo sorprendentemente breve. La sua eccezionale capacità di affrontare le sfide, unita al suo innato talento nel creare forti connessioni con i clienti, lo ha fatto emergere nel suo campo. Sopra ogni cosa, è stata la sua passione incessante per i viaggi a scatenare una scintilla in lui, alimentando la sua dedizione a questo affascinante settore."',
  Lang: 'Lingua',
  Last7Days: 'Ultimi 7 Giorni',
  LastMonth: 'Ultimo Mese',
  LastName: 'Cognome',
  LastWeek: 'Ultima Settimana',
  Level: 'Livello',
  Limit: 'Limite',
  Link: 'Collegamento',
  listOfDaysOfTheWeek: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
  listOfMonths: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
  LoadingPolicies: 'Caricamento Politiche',
  LocallyOwned: 'Di proprietà locale',
  Location: 'Posizione',
  Login: 'Accesso',
  Signin: 'Accedi',
  Signup: 'Registrati',
  LoginFailed: 'Accesso fallito!',
  SigninFailed: 'Accesso fallito!',
  SignupFailed: 'Registrazione fallita!',
  Logout: 'Disconnettiti',
  Logs: 'Log',
  LookingAvailabilitiesInTheSelectedDates: 'Ricerca disponibilità nelle date selezionate',
  LowHotelRates: 'Tariffe Alberghiere Basse',
  Manager: 'Manager',
  MarketingDirector: 'Direttore Marketing',
  Markup: 'Markup',
  Message: 'Messaggio',
  Messages: 'Messaggi',
  MessageSent: 'Messaggio inviato!',
  MoreServices: 'Altri Servizi',
  Name: 'Nome',
  NameLastName: 'Nome & Cognome',
  NEED_HELP: 'HAI BISOGNO DI AIUTO?',
  nights: 'notti',
  Nights: 'Notti',
  nNAvailability: 'Nessuna disponibilità',
  No: 'No',
  NoLocations: 'Nessuna posizione',
  None: 'Nessuno',
  NoPropertiesFound: 'Nessuna proprietà trovata',
  NoReimbursable: 'Non rimborsabile',
  NoResults: 'Nessun risultato',
  SearchingAvailabilitiesInTheSelectedWeek: 'Ricerca disponibilità nella settimana selezionata',
  NothingToSeeHere: 'Nessun contenuto qui!',
  NotVisible: 'Non visibile',
  Ok: 'Ok',
  OnBehalfOf: 'Per conto di',
  OpenSettings: 'Apri impostazioni',
  Order: 'Ordine',
  OrderHaveBeenRefunded: 'Ordine rimborsato',
  OrderId: 'ID Ordine',
  OrderReferrals: 'Referral Ordini',
  Orders: 'Ordini',
  OurMission: 'La Nostra Missione',
  OurServices: 'I Nostri Servizi',
  OurStory: 'La Nostra Storia',
  OurUtmostGoalIsToMeticulouslyPlan:
    'Il nostro obiettivo principale è pianificare e creare meticolosamente la migliore esperienza di viaggio per te e la tua famiglia, assicurandoci di scoprire le opzioni di viaggio più eccezionali disponibili. Inoltre, ci impegniamo a garantire che ogni individuo possa godere della propria vacanza con facilità. Quindi, se hai delle preferenze o delle destinazioni di viaggio specifiche in mente, o se stai celebrando un’occasione speciale, faccelo sapere! Che tu sia un viaggiatore solitario intrepido, una coppia avventurosa o un gruppo conviviale di familiari, amici o colleghi, Bellitours è appassionatamente dedicata ad aiutarti a esplorare il mondo e abbracciare infinite possibilità!',
  Paid: 'Pagato',
  SetAsPaid: 'Segna come Pagato',
  SetAsUnpaid: 'Segna come Non Pagato',
  PartneredWithLeadingHotelChains:
    'In collaborazione con le principali catene alberghiere e proprietà in affitto, offriamo alcune delle migliori tariffe alberghiere online. Controlla i nostri prezzi. Rimarrai sorpreso',
  Password: 'Password',
  ConfirmPassword: 'Conferma password',
  Payed: 'Pagato',
  Payload: 'Payload',
  PaymentDetails: 'Dettagli di Pagamento',
  PaymentInformation: 'Informazioni di Pagamento',
  PaymentType: 'Tipo di Pagamento',
  Pending: 'In sospeso',
  PendingToPaid: 'In sospeso da pagare',
  PendingToPay: 'in attesa di pagamento',
  Percentage: 'Percentuale',
  perNight: 'per notte',
  Phone: 'Telefono',
  PhoneNumber: 'Numero di telefono',
  Photos: 'Foto',
  PleaseCompleteTheRequiredInformation: 'Per favore, completa le informazioni richieste',
  Policies: 'Politiche',
  Policy: 'Politica',
  PopularAmenities: 'Servizi Popolari',
  PostalCode: 'Codice Postale',
  PreReserved: 'Pre-riservato',
  Price: 'Prezzo',
  PriceGreaterThan: 'Prezzo maggiore di',
  PrivacyCookiesPolicy: 'Politica sulla Privacy e sui Cookie',
  PrivacyStatement: 'Dichiarazione sulla Privacy',
  ProceedToRefund: 'Procedi al Rimborso',
  Profile: 'Profilo',
  Profit: 'Profitto',
  Promotion: 'Promozione',
  PromotionCouponText: 'Testo del Coupon Promozionale',
  PromotionText: 'Testo della Promozione',
  PropertyName: 'Nome della Proprietà',
  PropertyPrice: 'Prezzo della Proprietà',
  PropertyRating: 'Valutazione della Proprietà',
  PropertyType: 'Tipo di Proprietà',
  receipt: 'ricevuta',
  RecommendedAllInclusiveHotelsForYouToConsider: 'Hotel All Inclusive consigliati per te',
  Referral: 'Referral',
  ReferralCode: 'Codice Referral',
  ReferralCreated: 'Referral creato',
  Referrals: 'Referrals',
  ReferralUpdated: 'Referral aggiornato',
  ReferredBy: 'Riferito da',
  ReferringAgent: 'Agente Referente',
  Refunding: 'Rimborso',
  RefundOrder: 'Ordine di Rimborso',
  Reimbursable: 'Rimborsabile',
  Remove: 'Rimuovi',
  RemoveCoupon: 'Rimuovi Coupon',
  RemoveImage: 'Rimuovi immagine',
  RemoveRoom: 'Rimuovi Stanza',
  RequestToTheHotel: 'Richiesta all\'Hotel',
  Required: 'Obbligatorio',
  ReservationConfirmed: 'Prenotazione confermata!',
  ReservationInfo: 'Informazioni Prenotazione',
  ReservationNo: 'Numero Prenotazione',
  Reservations: 'Prenotazioni',
  ReservePrice: 'Prezzo di Riserva',
  Room: 'Stanza',
  rooms: 'stanze',
  SalesManager: 'Responsabile Vendite',
  Save: 'Salva',
  SaveOnYourNextStay: 'Risparmia sul tuo prossimo soggiorno',
  Saving: 'Risparmio',
  Search: 'Cerca',
  SearchALocation: 'Cerca una posizione',
  SearchADestinationsAndHotels: 'Cerca destinazioni e hotel',
  SearchDestinations: 'Cerca destinazioni',
  SearchHotels: 'Cerca hotel',
  searching: 'ricerca',
  Searching: 'Ricerca',
  SearchMore: 'Cerca di più',
  SearchMoreProperties: 'Cerca altre proprietà',
  Select: 'Seleziona',
  SelectACountry: 'Seleziona un paese',
  SelectDateAndTime: 'Seleziona Data e Ora',
  SelectGuests: 'Seleziona ospiti',
  SellPrice: 'Prezzo di Vendita',
  SendMessage: 'Invia Messaggio',
  ServingYouSince2018: 'In servizio dal 2018',
  Settings: 'Impostazioni',
  ShowAll: 'Mostra tutto',
  ShowInMap: 'Mostra sulla Mappa',
  ShowLess: 'Mostra meno',
  SorryAnUnexpectedErrorHasOccurred: 'Spiacenti, si è verificato un errore imprevisto.',
  Sort: 'Ordina',
  SortBy: 'Ordina per',
  SpecialRequestForTheHotel: 'Richiesta speciale per l\'Hotel',
  stars: 'stelle',
  StartAt: 'Inizia a',
  StartDate: 'Data di Inizio',
  StartPlanningWithUs: 'Inizia a pianificare con noi',
  Status: 'Stato',
  StripeCharge: 'Addebito Stripe',
  StripeCurrency: 'Valuta Stripe',
  StripeCustomerId: 'ID Cliente Stripe',
  StripeReceiptUrl: 'URL Ricevuta Stripe',
  StripeRefund: 'Rimborso Stripe',
  StripeStatus: 'Stato Stripe',
  Subject: 'Oggetto',
  Subscribe: 'Iscriviti',
  Subtotal: 'Subtotale',
  Support: 'Supporto',
  tel: 'tel',
  TermsOfService: 'Termini di Servizio',
  Text: 'Testo',
  TheCouponDoesNotApplyPleaseRemoveTheCouponBeforeProceed: 'Il coupon non è applicabile, per favore rimuovi il coupon prima di procedere',
  TheHotelHasProvidedTheFollowingImportantInfo:
    'L\'hotel ha fornito le seguenti informazioni importanti che devi rivedere e accettare prima di completare la prenotazione:',
  ThereIsNoAvailabilityInTheSelectedDates: 'Non c\'è disponibilità nelle date selezionate',
  ThereWasAnErrorSendingTheMessagePleaseTryAgain: 'C\'è stato un errore nell\'invio del messaggio. Per favore, riprova',
  ThereWasAnErrorWithYourCreditCard: 'C\'è stato un errore con la tua carta di credito, per favore riprova',
  ThisFieldIsRequired: 'Questo campo è obbligatorio',
  ThisMonth: 'Questo Mese',
  ThisWebsiteUsesCookiesToEnhanceUserExperience:
    'Questo sito web utilizza i cookie per migliorare l\'esperienza dell\'utente e per analizzare le prestazioni e il traffico del nostro sito. Condividiamo anche informazioni sull\'uso del nostro sito con i nostri partner di social media, pubblicità e analisi.',
  ThisWeek: 'Questa Settimana',
  Title: 'Titolo',
  Today: 'Oggi',
  TopHotels: 'Top hotel',
  total: 'totale',
  Total: 'Totale',
  TotalPayed: 'Totale Pagato',
  TotalPrice: 'Prezzo Totale',
  Tours: 'Tour',
  ToursDestinations: 'Destinazioni dei Tour',
  TravelAdvisor: 'Consulente di Viaggio',
  TravelAgent: 'Agente di Viaggio',
  TravelInsurance: 'Assicurazione di Viaggio',
  TrendingDestinations: 'Destinazioni di Tendenza',
  Type: 'Tipo',
  TypeOfProfit: 'Tipo di profitto',
  UnexpectedError: 'Errore Improvviso',
  Unit: 'Unità',
  UnlockExclusiveTravelInspirationForYourNextGetawaySubscribeToOurNewsletter:
    'Sblocca ispirazioni di viaggio esclusive per la tua prossima fuga, iscriviti alla nostra newsletter.',
  Unpaid: 'Non Pagato',
  Unrated: 'Non Valutato',
  Unselect: 'Deseleziona',
  Update: 'Aggiorna',
  UpdatedAt: 'Aggiornato il',
  UpsSomethingWasWrong: 'Ops. Qualcosa è andato storto!',
  Used: 'Utilizzato',
  VacationPackages: 'Pacchetti Vacanze',
  Value: 'Valore',
  View: 'Visualizza',
  viewLess: 'visualizza meno',
  viewMore: 'visualizza di più',
  Visible: 'Visibile',
  walking: 'camminata',
  WeAreContinuouslyDevelopingOurSystem:
    'Stiamo continuamente sviluppando il nostro sistema, le nostre competenze e conoscenze di viaggio per offrire il miglior servizio clienti.',
  Website: 'Sito Web',
  WeDLoveToHearFromYou: 'Ci piacerebbe ricevere tue notizie.',
  WeHaveSpecialToursDestinationsAndPackages:
    'Abbiamo destinazioni e pacchetti tour speciali pronti per te. Personalizzati solo per i nostri clienti, creiamo percorsi e viaggi speciali',
  WelcomeYouVeBeenReferredToOurWebsiteBy: '"Benvenuto" Sei stato indirizzato al nostro sito da',
  WeLlGiveYouThePriceYouWillEnjoyTheTime: 'Ti daremo il prezzo. Ti divertirai',
  Yes: 'Sì',
  Yesterday: 'Ieri',
  YouCanUseBellitoursDirectlyInYourMobileForABetterExperience: 'Puoi utilizzare Bellitours direttamente sul tuo mobile per un\'esperienza migliore.',
  YourContactInfo: 'Le tue informazioni di contatto',
  YourMessageHaveBeenSent: 'Il tuo messaggio è stato inviato!',
  YourPaymentInfo: 'Le tue informazioni di pagamento',
  Client: 'Cliente',
  Customer: 'Cliente',
  Discount: 'Sconto',
  orLoginWithEmailAndPassword: 'o accedi con email e password',
  BackToHome: 'Torna alla Homepage',
  ForgotPassword: 'Hai dimenticato la password?',
  DontHaveAnAccount: 'Non hai un account?',
  RecoverPassword: 'Recupera password',
  AlreadyHaveAnAccount: 'Hai già un account?',
  PasswordsDonTMatch: 'Le password non corrispondono',
  ThankYouForSigningUp: 'Grazie per esserti iscritto',
  ToCompleteYourRegistrationCheckEmail: 'Per completare la tua registrazione, controlla la tua email per un link di verifica. Clicca sul link per confermare il tuo indirizzo email e attivare il tuo account. Se non vedi l\'email nella tua casella di posta, controlla la cartella spam o indesiderata',
  VerifyingEmail: 'Verifica email',
  InvalidLink: 'Link non valido',
  LinkExpired: 'Link scaduto',
  EmailValidatedSuccessfully: 'Email verificata con successo',
  TheVerificationLinkYouUsedIsInvalid: 'Ci dispiace, ma il link di verifica che hai utilizzato non è valido. Per favore, controlla il link nella tua email e riprova, o richiedi un nuovo link di verifica.',
  TheVerificationLinkHasExpired: 'Il link di verifica è scaduto. Per favore richiedi una nuova email di verifica per confermare il tuo indirizzo.',
  YourAccountHasAlreadyBeenVerified: 'Il tuo account è già stato verificato. Ora puoi accedere utilizzando le tue credenziali.',
  YourEmailHasBeenSuccessfullyVerified: 'Grazie! La tua email è stata verificata con successo. Ora puoi accedere al tuo account.',
  YourEmailHasNotBeenVerified: 'La tua email non è stata verificata?',
  ResendEmail: 'Reinvia email',
  ResendVerificationEmail: 'Reinvia email di verifica',
  FailedToResendEmailPleaseTryAgain: 'Impossibile reinviare l\'email, per favore riprova.',
  VerificationEmailHasBeenResend: 'L\'email di verifica è stata reinviata',
  AVerificationEmailWillBeSendToYourEmailAddress: 'Un\'email di verifica sarà inviata al tuo indirizzo email se l\'email è ancora in attesa di verifica. Per favore controlla la tua casella di posta (e la cartella spam/indesiderata) per l\'email e clicca sul link per verificare il tuo account.',
  EmailHasBeenSent: 'Email inviata',
  EmailToRecoverPassHasBeenSend: 'Un\'email per recuperare la tua password è stata inviata all\'indirizzo email specificato. Per favore controlla la tua casella di posta (e la cartella spam/indesiderata) per l\'email e clicca sul link per recuperare la tua password.',
  RecoverPass: 'Recupera password',
  UpdateYourPassword: 'Aggiorna la tua password',
  PasswordSuccessfullyChanged: 'Password cambiata con successo',
  YourPasswordHasBeenUpdatedSuccessfully: 'La tua password è stata aggiornata con successo. Per favore utilizza la tua nuova password la prossima volta che accedi. Se non hai richiesto questa modifica, contatta immediatamente il nostro team di supporto.',
  UpdatePasswordFailed: 'Aggiornamento password fallito!',
  AnUnexpectedErrorOccurredWhileUpdatingPassword: 'Si è verificato un errore imprevisto durante l\'aggiornamento della tua password. Per favore riprova più tardi. Se il problema persiste, contatta il nostro team di supporto',
  ThePasswordResetLinkIsInValidOrExpired: 'Spiacenti, il link per il reset della password non è valido o è scaduto. Per favore richiedi un nuovo link per il reset della password e riprova',
  PasswordUpdatedSuccessfully: 'La tua password è stata aggiornata con successo. Ora puoi accedere con la tua nuova password. Se non hai richiesto questa modifica, contatta immediatamente il nostro team di supporto.',
  YouDontHaveAccessToTheRequestedPage: 'Non hai i permessi per accedere alla pagina richiesta',
  Role: 'Ruolo',
  AuthProviders: 'Fornitori di Autenticazione',
  Verified: 'Verificato',
  Users: 'Utenti',
  User: 'Utenti',
  Hi: 'Ciao',
  Favorites: 'Preferiti',
  ProvideYourPersonalDetails: 'Fornisci i tuoi dettagli personali',
  CheckYourFavoriteProperties: 'Controlla le tue proprietà preferite',
  SeeYourReservations: 'Guarda le tue prenotazioni',
  Security: 'Sicurezza',
  CheckYouSecurityAndSettings: 'Controlla la tua sicurezza e le impostazioni',
  NotProvided: 'Non fornito',
  Gender: 'Genere',
  Bio: 'Bio',
  DateOfBirth: 'Data di nascita',
  ShowPricesBy: 'Mostra prezzi raggruppati per',
  SearchPricesBy: 'Cerca prezzi per intervallo',
  ShowAdvancedSearch: 'Mostra opzioni di data flessibile',
  HideAdvancedSearch: 'Nascondi opzioni di data flessibile',
  YourFavoriteProperties: 'Le tue proprietà preferite',
  NoDiscount: 'Nessuno sconto',
  Commission: 'Commissione',
  GetGender: (gender: Gender) => ({
    [Gender.Female]: 'Femminile',
    [Gender.Male]: 'Maschile',
    [Gender.Unspecified]: 'Non specificato',
    [Gender.Undisclosed]: 'Non dichiarato',
  }[gender]),
  RequireAtLeastNChars: (n: number) => `Richiede almeno ${n} caratteri`,
  acceptTerms: (props: { privacyStatementLink: ReactNode; termsOfServiceLink: ReactNode }) => (
    <>
      Cliccando su questo pulsante, confermo di aver letto il {props.privacyStatementLink} e il Government Travel Advice, e di aver letto e accettato
      i {props.termsOfServiceLink}.
    </>
  ),
  andNChildren: (amount: number) => `e ${amount} bambini`,
  AnErrorHasOccurred: (message) => `Si è verificato un errore! ${message || ''}`,
  AnErrorHasOccurredTryAgain: (message) => `Si è verificato un errore! ${message || 'Per favore, riprova.'}`,
  AnUnexpectedErrorHasOccurredPleaseReloadThePage: (message) =>
    `Si è verificato un errore imprevisto. Per favore, ricarica la pagina. ${message || ''}`,
  DoYouWantToActivateDeactivateCoupon: (props: { activate: boolean }) =>
    `Vuoi ${props.activate ? 'attivare' : 'disattivare'} questo coupon?`,
  DoYouWantToUpdateThisOrderReferralAsPaidUnpaid: (props: { paid: boolean }) =>
    `Vuoi aggiornare il referral di quest'ordine come ${props.paid ? 'pagato' : 'non pagato'}`,
  foundNProperties: (amount: number) => `Trovate ${amount} proprietà`,
  goodNewsPriceHasDecreasedToNewTotalMsg: (total: number) => (
    <>
      Il prezzo totale della tua prenotazione è aumentato a causa della variazione dei prezzi dinamici. Il nuovo totale è <b>{total}</b>. Puoi scegliere di procedere con
      l'importo aggiornato o annullare la tua prenotazione. Per favore, conferma la tua scelta per continuare o annullare.
    </>
  ),
  hotelConsiderChildrenFrom: (props: { from: ReactNode; to: ReactNode }) => (
    <>
      L'hotel considera i bambini da {props.from} a {props.to} anni.
    </>
  ),
  MinLengthIs: (len) => `La lunghezza minima è ${len}`,
  MinValueIs: (value) => `Il valore minimo è ${value}`,
  protectionOfPersonalInfoMsg: (
    <>
      Garantiamo la protezione delle tue informazioni personali attraverso la trasmissione sicura e la memorizzazione criptata.
      <br /> Anche se i pagamenti sono generalmente elaborati negli Stati Uniti, alcuni fornitori di servizi di viaggio (come hotel o compagnie aeree) potrebbero elaborare il tuo
      pagamento al di fuori degli Stati Uniti, il che potrebbe comportare l'applicazione di una commissione per transazione estera da parte dell'emittente della tua carta.
    </>
  ),
  selectNRoomForPax: (props: { room: any; pax: any }) => (
    <>
      Seleziona {props.room} stanza per {props.pax}
    </>
  ),
  ValueNeedsToBeGreaterThanN: (number) => `Il valore deve essere maggiore di ${number}`,
}
