import * as React from 'react';
import {
  Box,
  Typography,
  Container,
  MenuItem,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  Tooltip,
  Menu,
  SxProps,
  Divider,
  ListItemIcon,
} from '@mui/material';
import headerImage from '../../components/assets/header.jpeg';
import logoImage from '../../components/assets/logo.png';
import Link from '@mui/material/Link';
import { Link as LinkRouter } from 'react-router-dom';
import Popover from '../../components/Popover/Popover';
import LanguageIcon from '@mui/icons-material/Language';
import Dialog from '../../components/Dialogs/Dialog';
import MessageBox from '../../components/MessageBox/MessageBox';
import SearchPage from './SearchPage';
import SearchHotelsInputs from '../../components/Inputs/SearchHotelsInput';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { getCurrency, setCurrency } from '../../helpers/currencies';
import { languageName, languageNamesPerLanguage, setLanguage, t } from '../../translations';
import { Currency, Language } from '../../types';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useAuth } from '../auth/AuthProvider';
import { getReferralId } from '../Referral';
import Image from 'mui-image';
import LogoutIcon from '@mui/icons-material/Logout';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ApartmentIcon from '@mui/icons-material/Apartment';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import { UserRole } from '../../types/auth';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';

const phoneNumber = process.env.REACT_APP_BELLITOURS_PHONE;

const sx = {
  navbar: {
    width: '100%',
    // height: '320px',
    backgroundImage: `url(${headerImage})`,
    backgroundRepeat: 'no-repeat',
    // backgroundAttachment: 'fixed',
    // backgroundSize: 'cover'
    backgroundSize: 'cover',
    backgroundAttachment: {
      xs: 'fixed',
      sm: 'inherit',
    },
    pb: 4,
  },
  navLinkContainer: {
    float: 'right',
    display: {
      xs: 'none',
      sm: 'flex',
    },
    marginLeft: 'auto',
  },
  break: {
    flexBasis: '100%',
    height: '0px',
    display: {
      sm: 'none',
    },
  },
  container: {
    paddingTop: 1,
    maxWidth: '1300px',
    height: '100%',
    position: 'relative',
    px: {
      xs: '20px !important',
      md: '50px !important',
      lg: '0px !important',
    },
  },
  menu: {
    cursor: 'pointer',
    fontWeight: {
      // xs: '',
      sm: 'bold',
    },
    fontSize: 14,
    color: {
      xs: 'text.primary',
      sm: 'white',
    },
    ml: {
      xs: 0,
      sm: 3,
    },
    display: 'block',
  },
  language: {
    minWidth: '70px',
    display: 'inline-block',
  },
  navLink: {
    fontWeight: 'bold',
    fontSize: 14,
    color: 'white',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
    ml: {
      xs: 1.5,
      sm: 3,
    },
  },
  submenuNavLink: {
    // color: 'white',
    textDecoration: 'none',
  },
  planner: {
    background: '#f3f3f5',
    color: 'black',
    '&:hover': {
      backgroundColor: '#f3f3f5',
      borderColor: '#0062cc',
      // boxShadow: 'none',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
    ml: {
      xs: 2,
      sm: 3,
    },
    display: {
      xs: 'none',
      sm: 'inline',
    },
  },
  logo: {
    marginTop: 2,
    display: 'inline-block',
    width: {
      // xs: '90px',
      xs: '180px',
      md: '270px',
    },
    // original logo size 3321x1087, the ratio os 3.05
    height: {
      // xs: '29px',
      xs: '58px',
      md: '87px',
    },
    backgroundImage: `url(${logoImage})`,
    backgroundRepeat: 'no-repeat',
    // backgroundAttachment: 'fixed',
    // backgroundSize: 'cover'
    backgroundSize: '100% 100%',
    // marginLeft: '-26px',
  },
  navigation: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  mainText: {
    mt: 0,
    color: 'white',
    fontSize: 28,
    fontWeight: 'bold',
  },
  secondaryText: {
    fontSize: 18,
    color: 'white',
    fontWeight: 'bold',
    mb: -1,
  },
  mainSearch: {
    text: {
      color: 'white',
      fontSize: 16,
      mb: 0.5,
      ml: 1,
    },
  },
};

const links = [
  { to: '/', text: 'Hotel' },
  { to: 'https://bellitours.mybookingplatform.com/us/Tour/Search', text: t.Tours },
  { to: 'https://bellitours.mybookingplatform.com/us/Car/Search', text: t.Cars },
  { to: 'https://bellitours.mybookingplatform.com/us/Activities/Search', text: t.Activities },
  { to: 'https://bellitours.mybookingplatform.com/us/Insurance/Search', text: t.TravelInsurance },
];

const links2 = [
  { to: '/contact', text: t.Support },
  // { to: 'https://book.bellitours.com', text: t.Deals },
];

const MainSearch = () => {
  const navigate = useNavigate();
  const search = (searchProps) => navigate(SearchPage.link(searchProps));

  return (
    <>
      <Typography sx={sx.mainSearch.text}>Hotels</Typography>
      <SearchHotelsInputs onSearch={search} />
    </>
  );
};

const UserTooltipNavbar = ({ showAsLink, linkSx }: { showAsLink?: boolean; linkSx?: SxProps }) => {
  const navigateTo = useNavigate();
  const { isLoggedIn, logout, currentUser } = useAuth();

  const [anchorElUser, setAnchorElUser] = React.useState<any>(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  if (!isLoggedIn) {
    return showAsLink ? (
      <Link component={LinkRouter} to={'/auth/signin'} sx={linkSx}>
        <Typography color="text.primary">{t.Signin}</Typography>
      </Link>
    ) : (
      <Button variant="contained" component={LinkRouter} to={'/auth/signin'} endIcon={<AccountCircleRoundedIcon />} size="medium">
        {t.Signin}
      </Button>
    );
  }

  return (
    <Box>
      <Tooltip title={t.OpenSettings}>
        <React.Fragment>
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, color: 'white' }}>
            {currentUser?.image ? (
              <Box sx={{ width: '36px', height: '36px', borderRadius: '50%', overflow: 'hidden', border: '2px solid white' }}>
                <Image src={currentUser?.image || ''} duration={0} />
              </Box>
            ) : (
              <AccountCircleRoundedIcon color='primary'  sx={{ fontSize: '40px' }} />
            )}
          </IconButton>
        </React.Fragment>
      </Tooltip>
      <Menu
        sx={{ mt: '45px', minWidth: '200px' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem
          disabled
          sx={{
            minWidth: '300px',
            '&.Mui-disabled': {
              opacity: '1 !important',
            },
          }}
        >
          <Box sx={{ width: '100%', display: 'flex' }}>
            {currentUser?.image && (
              <Box sx={{ display: 'inline-block', width: '36px', height: '36px', borderRadius: '50%', overflow: 'hidden', mt: 1 }}>
                <Image src={currentUser?.image || ''} duration={0} />
              </Box>
            )}
            <Box sx={{ ml: 2 }}>
              <Typography variant="h6">
                {t.Hi}, {currentUser?.fullName}
              </Typography>
              <Typography variant="body1">{currentUser?.email}</Typography>
            </Box>
          </Box>
        </MenuItem>

        <Divider />
        <MenuItem onClick={() => navigateTo('/account')} sx={{ py: 1.5 }}>
          <ListItemIcon>
            <PermIdentityIcon />
          </ListItemIcon>
          <Typography>{t.Profile}</Typography>
        </MenuItem>
        <MenuItem onClick={() => navigateTo('/account/bookings')} sx={{ py: 1.5 }}>
          <ListItemIcon>
            <ApartmentIcon />
          </ListItemIcon>
          <Typography>{t.Bookings}</Typography>
        </MenuItem>
        <MenuItem onClick={() => navigateTo('/account/favorites')} sx={{ py: 1.5 }}>
          <ListItemIcon>
            <FavoriteBorderIcon />
          </ListItemIcon>
          <Typography>{t.Favorites}</Typography>
        </MenuItem>
        {currentUser?.role === UserRole.Admin && (
          <MenuItem onClick={() => navigateTo('/admin')} sx={{ py: 1.5 }}>
            <ListItemIcon>
              <AdminPanelSettingsIcon />
            </ListItemIcon>
            <Typography>{t.Admin}</Typography>
          </MenuItem>
        )}
        <Divider />
        <MenuItem onClick={logout} sx={{ py: 1.5 }}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <Typography>{t.Logout}</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};

const Navbar = (props) => {
  const moreServicesMenu = (
    <Popover
      openOn="click"
      inline
      horizontal={-20}
      target={
        <Typography component={'span'} sx={sx.menu}>
          {t.MoreServices}
        </Typography>
      }
      renderChildren={({ close }) => (
        <Box
          sx={
            {
              /*background: 'rgb(43, 104, 154)' */
            }
          }
        >
          {links.map((link) => (
            <MenuItem key={link.to}>
              <Link sx={sx.submenuNavLink} component={LinkRouter} to={link.to} target="_blank">
                {link.text}
              </Link>
            </MenuItem>
          ))}
        </Box>
      )}
    />
  );

  const currencySelector = (
    <Popover
      openOn="click"
      inline
      horizontal={-20}
      target={
        <Typography component={'span'} sx={{ ...sx.menu, py: { xs: 1.5, sm: 0, display: 'block' } }}>
          {getCurrency()}
        </Typography>
      }
      renderChildren={({ close }) => (
        <Box>
          {Object.values(Currency).map((currency) => (
            <MenuItem
              key={currency}
              sx={{ width: { xs: '100%' } }}
              onClick={() => {
                setCurrency(currency);
              }}
            >
              <Typography color="text.primary">{currency}</Typography>
            </MenuItem>
          ))}
        </Box>
      )}
    />
  );

  const languageSelector = (
    <Popover
      openOn="click"
      inline
      horizontal={-20}
      target={
        <Typography component={'span'} sx={{ ...sx.menu, ...sx.language, py: { xs: 1.5, sm: 0, display: 'block' } }}>
          <LanguageIcon sx={{ mb: '-4px', fontSize: 17 }} /> {languageName}
        </Typography>
      }
      renderChildren={({ close }) => (
        <Box>
          {Object.values(Language).map((language) => (
            <MenuItem
              key={language}
              onClick={() => {
                setLanguage(language);
                close();
              }}
            >
              {languageNamesPerLanguage[language]}
            </MenuItem>
          ))}
        </Box>
      )}
    />
  );

  const planner = (
    <Dialog
      // disableCloseOnClickAway
      titleLabel={t.StartPlanningWithUs}
      renderTarget={({ openDialog }) => (
        <Button sx={sx.planner} variant="contained" size="small" onClick={openDialog}>
          {t.Contact}
        </Button>
      )}
      renderContent={() => (
        <Box sx={{ '& .bt-message-box': { p: 2, pt: 0, color: 'red' } }}>
          <Box pl={2}>
            <Typography>{t.WeDLoveToHearFromYou}</Typography>
            <Typography>
              {t.ForDirectAssistanceCallOurOfficeAt} <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
            </Typography>
          </Box>
          <MessageBox />
        </Box>
      )}
    />
  );

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const isShowingReferralInTop = Boolean(getReferralId()) && !window.location.pathname.startsWith('/account');

  return (
    <Box sx={sx.navbar}>
      <Container sx={sx.container}>
        <Box sx={sx.navigation}>
          <Link sx={sx.logo} component={LinkRouter} to={'/'}></Link>
          <IconButton
            aria-label="open drawer"
            onClick={() => setIsDrawerOpen(true)}
            edge="start"
            sx={{
              color: 'white',
              display: {
                sm: 'none',
              },
            }}
          >
            <MenuIcon sx={{ fontSize: '30px' }} />
          </IconButton>

          <Box sx={sx.break} />
          <Box sx={{ ...sx.navLinkContainer, alignItems: 'center' }}>
            {moreServicesMenu}
            {currencySelector}
            {languageSelector}

            {links2.map((link) => (
              <Link key={link.to} sx={sx.navLink} component={LinkRouter} to={link.to} target="_blank">
                {link.text}
              </Link>
            ))}

            {planner}

            <Box sx={{ ml: 2 }}>
              <UserTooltipNavbar />
            </Box>
          </Box>
        </Box>

        {props.skipHeader || (
          <Box mb={2} sx={{ display: { xs: 'none', sm: 'block' } }}>
            <Typography sx={sx.mainText}>{t.SaveOnYourNextStay}</Typography>
            <Typography sx={sx.secondaryText}>{t.WeLlGiveYouThePriceYouWillEnjoyTheTime}</Typography>
          </Box>
        )}

        {props.withNavbar && (
          <>
            <Box mt={1}>
              <MainSearch />
            </Box>
          </>
        )}
      </Container>

      <Drawer anchor="left" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        <Box sx={{ width: '100vw', pt: isShowingReferralInTop ? 10 : 5 }} role="presentation">
          <IconButton
            aria-label="close"
            onClick={() => setIsDrawerOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: isShowingReferralInTop ? 50 : 10,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <List>
            <ListItem disablePadding>
              <ListItemButton sx={{ pl: 5 }}>
                <UserTooltipNavbar showAsLink linkSx={{ ...sx.submenuNavLink, py: 1.5, width: '100%', display: 'block' }} />
              </ListItemButton>
            </ListItem>

            {links.map((link, index) => (
              <ListItem key={index} disablePadding>
                <ListItemButton sx={{ pl: 5 }}>
                  <Link
                    sx={{ ...sx.submenuNavLink, py: 1.5, width: '100%', display: 'block' }}
                    component={LinkRouter}
                    to={link.to}
                    target="_blank"
                  >
                    <Typography color="text.primary">{link.text}</Typography>
                  </Link>
                </ListItemButton>
              </ListItem>
            ))}

            <ListItem disablePadding>
              <ListItemButton sx={{ pl: 5 }}>{currencySelector}</ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton sx={{ pl: 5 }}>{languageSelector}</ListItemButton>
            </ListItem>

            {links2.map((link, index) => (
              <ListItem key={index} disablePadding sx={{}}>
                <ListItemButton sx={{ pl: 5 }}>
                  <Link
                    sx={{ ...sx.submenuNavLink, py: 1.5, width: '100%', display: 'block' }}
                    color="text.primary"
                    component={LinkRouter}
                    to={link.to}
                    target="_blank"
                  >
                    {link.text}
                  </Link>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </Box>
  );
};

export default Navbar;
