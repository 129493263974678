import { Box, Link, Typography } from '@mui/material';
import { Link as LinkRouter } from 'react-router-dom';
import { t } from '../../../translations';

export const SigninOrSignupLink = ({ signin, signup }: { signin?: boolean; signup?: boolean }) => {
  const signinLink = <AuthLink anchorText={t.Signin} anchorLink="/auth/signin" />;
  const signupLink = <AuthLink anchorText={t.Signup} anchorLink="/auth/signup" />;
  return (
    <Box sx={{ textAlign: 'center' }}>
      {signin && !signup && signinLink}
      {!signin && signup && signupLink}
      {signinLink} or {signupLink}
    </Box>
  );
};

export const AuthLink = ({ anchorLink, anchorText }: { anchorLink: string; anchorText: string }) => {
  return (
    <Typography fontWeight="bold" sx={{ display: 'inline' }}>
      <Link component={LinkRouter} to={anchorLink} sx={{ textDecoration: 'none', mb: 3 }}>
        {anchorText}
      </Link>
    </Typography>
  );
};

const MessageWithLink = ({ message, anchorLink, anchorText }: { message: string; anchorLink: string; anchorText: string }) => {
  return (
    <Box sx={{ textAlign: 'center' }}>
      <Typography variant="subtitle1">
        {message} <AuthLink anchorLink={anchorLink} anchorText={anchorText} />
      </Typography>
    </Box>
  );
};

export default MessageWithLink;
