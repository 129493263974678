import { useRef, useState } from 'react';
import { Alert, Box, Button, Typography } from '@mui/material';
import { useAuth } from './AuthProvider';
import { t } from '../../translations';
import GoogleAuth from './GoogleAuth';
import { z } from 'zod';
import { useForm } from '../../hooks/useForm';
import { getProp } from '../../helpers/utils';
import AuthMessageInfo from './components/AuthMessageInfo';
import OrSeparator from './components/OrSeparator';
import MessageWithLink, { SigninOrSignupLink } from './components/MessageWithLink';

const SignupSchema = z
  .object({
    fullName: z.string().trim().min(1, t.Required),
    email: z.string().min(1, t.Required).email(t.InvalidEmail),
    pass: z.string().min(5, t.RequireAtLeastNChars(5)),
    passConfirmation: z.string().min(1, t.Required),
  })
  .refine((data) => data.pass === data.passConfirmation, {
    message: t.PasswordsDonTMatch,
    path: ['passConfirmation'],
  });

type SignupData = z.infer<typeof SignupSchema>;

const SignupPage = () => {
  const { signup } = useAuth();

  const [signedupSuccessfully, setSignedupSuccessfully] = useState(false);

  const dataLoader = useRef(
    async (): Promise<SignupData> => ({
      fullName: '',
      email: '',
      pass: '',
      passConfirmation: '',
    })
  );

  const save = useRef(async (signupData: SignupData) => {
    await signup(signupData);
    setSignedupSuccessfully(true);
  });

  const { errorMessage, textInput, passwordInput, saveForm, editable, saving } = useForm({
    schema: SignupSchema,
    defaultSaveErrorMessage: t.SignupFailed,
    dataLoader: dataLoader.current,
    save: save.current,
  });

  if (signedupSuccessfully) {
    return (
      <Box>
        <AuthMessageInfo title={t.ThankYouForSigningUp} message={t.ToCompleteYourRegistrationCheckEmail} />
        <SigninOrSignupLink />
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h4" mb={4} textAlign="center" fontWeight="bold">
        {t.Signup}
      </Typography>

      <Box component="form" onSubmit={saveForm}>
        {textInput(getProp<SignupData>('fullName'), { label: t.FullName, sx: { mb: 2 } })}
        {textInput(getProp<SignupData>('email'), { label: t.Email, sx: { mb: 2 } })}
        {passwordInput(getProp<SignupData>('pass'), { label: t.Password, sx: { mb: 2 } })}
        {passwordInput(getProp<SignupData>('passConfirmation'), { label: t.Password, sx: { mb: 2 } })}

        {errorMessage && !saving && (
          <Alert sx={{ mb: 3 }} severity="error">
            {errorMessage}
          </Alert>
        )}

        <Button type="submit" variant="contained" disabled={!editable || saving} fullWidth size="large" sx={{ mb: 2 }}>
          {t.Signup}
        </Button>

        <MessageWithLink message={t.AlreadyHaveAnAccount} anchorText={t.Signin} anchorLink="/auth/signin" />
      </Box>

      <OrSeparator />

      <Box sx={{ mb: 1 }}>
        <GoogleAuth />
      </Box>
    </Box>
  );
};

export default SignupPage;
