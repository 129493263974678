import { ReactNode } from 'react';
import { Gender } from '../types/auth';

export const es = {
  About: 'Acerca de',
  AboutBellitours: 'Acerca de Bellitours',
  Accept: 'Aceptar',
  AcceptCookies: 'Aceptar Cookies',
  AcceptedPaymentMethods: 'Métodos de Pago Aceptados',
  AccommodationType: 'Tipo de Alojamiento',
  Account: 'Cuenta',
  AccountManager: 'Gerente de Cuenta',
  Actions: 'Acciones',
  Active: 'Activo',
  Activities: 'Actividades',
  AddAnotherRoom: 'Agregar otra Habitación',
  AddCoupon: 'Agregar Cupón',
  AddFilter: 'Agregar Filtro',
  AddNewBanner: 'Agregar Nuevo Banner',
  Address: 'Dirección',
  Admin: 'Administrador',
  AdminLinks: 'Enlaces de Administrador',
  adult: 'adulto',
  adults: 'adultos',
  Age: 'Edad',
  Agent: 'Agente',
  AgentName: 'Nombre del Agente',
  AllowNewBookings: 'Permitir Nuevas Reservas',
  AllowNewBookingsOutsideMoldova: 'Permitir nuevas reservas fuera de Moldavia',
  ProductionMode: 'Mode de producción',
  TestMode: 'Modo de prueba',
  Amenities: 'Servicios',
  Amount: 'Cantidad',
  AmountToPay: 'Cantidad a Pagar',
  and1Child: 'y 1 niño',
  apartment: 'apartamento',
  Apply: 'Aplicar',
  AreYouSureYouWantToRefundThisOrder: '¿Estás seguro de que quieres reembolsar este pedido?',
  Avg: 'Prom.',
  BackToHotelResults: 'Volver a los Resultados del Hotel',
  Balance: 'Balance',
  banner: 'banner',
  Banners: 'Banners',
  BellitoursEstablishedIn2018takesPride:
    'Bellitours, establecido en 2018, se enorgullece de ser una empresa de propiedad y operación local que atiende a agentes de viajes individuales. Les brindamos la oportunidad de unirse a nuestra red, vender viajes y convertirse en miembros valiosos de nuestro equipo dedicado. Si bien nuestro enfoque principal es servir a clientes en los Estados Unidos, también extendemos nuestros servicios a clientes en toda Europa. Reconocemos que la industria de viajes está experimentando transformaciones rápidas y atribuimos nuestro éxito al apoyo inquebrantable que recibimos de nuestra comunidad, incluyéndote a ti. Para seguir conectados con la comunidad del suroeste de EE.UU., participamos activamente en actividades y eventos dedicados a mejorar la calidad de vida en nuestra región. En respuesta a los desafíos planteados por la pandemia en 2021, hicimos nuestro sistema accesible y reservable en línea, lo que nos permitió establecer una agencia de viajes en línea (OTA) que ofrece servicios completos de reservas de viajes en todo EE.UU. Este paso fue crucial para adaptarnos al panorama cambiante y satisfacer las necesidades de nuestros clientes de manera conveniente y accesible.',
  BillingAddress: 'Dirección de facturación',
  BillingCard: 'Tarjeta de facturación',
  BillingClient: 'Cliente de facturación',
  Book: 'Reservar',
  Booking: 'Reserva',
  Bookings: 'Reservas',
  BookingAndPaymentReceiptsSentToEmail: 'Los recibos de reserva y pago han sido enviados a tu correo electrónico.',
  BookingDetails: 'Detalles de la reserva',
  BookingReference: 'Referencia de reserva',
  BookingTermsConditions: 'Términos y condiciones de reserva',
  ByEmail: 'Por correo electrónico',
  CallUs: 'Llámanos',
  Cancel: 'Cancelar',
  CancellationPolicies: 'Políticas de cancelación',
  CancellationPolicy: 'Política de cancelación',
  Cancelled: 'Cancelado',
  Refunded: 'Refondado',
  CardNumber: 'Número de tarjeta',
  Cars: 'Autos',
  Category: 'Categoría',
  CEO: 'CEO',
  Certificated: 'Certificado',
  Certifications: 'Certificaciones',
  ChargeType: 'Tipo de cargo',
  CheckAvailability: 'Ver disponibilidad',
  Checkin: 'Checkin',
  CheckIn: 'Checkin',
  CheckInAndCheckOutDatesRequired: 'Se requieren fechas de checkin y checkout',
  CheckinDate: 'Fecha de checkin',
  CheckOut: 'Check-out',
  CheckoutDate: 'Fecha de checkout',
  children: 'niños',
  CityTax: 'Impuesto de la ciudad',
  Clear: 'Limpiar',
  ClientName: 'Nombre del cliente',
  ClientObservations: 'Observaciones del cliente',
  Close: 'Cerrar',
  ClosedPlaces: 'Lugares cerrados',
  Code: 'Código',
  Company: 'Compañía',
  CompleteBooking: 'Completar reserva',
  ConfirmationNo: 'Número de confirmación',
  ConfirmBooking: 'Confirmar reserva',
  Confirmed: 'Confirmado',
  ConfirmRefund: 'Confirmar reembolso',
  Contact: 'Contacto',
  ContactSupport: 'Contactar soporte',
  CookiePolicy: 'Política de cookies',
  CopyLink: 'Copiar enlace',
  CopyReferralCode: 'Copiar código de referencia',
  Copyright2024ByBellitours: 'Derechos de autor © 2024 por Bellitours',
  CountryIsRequired: 'Se requiere país',
  CouponApplied: 'Cupón aplicado',
  CouponCreated: 'Cupón creado',
  CouponDiscount: 'Descuento del cupón',
  Coupons: 'Cupones',
  Coupon: 'Cupón',
  DiscountOff: 'de descuento',
  Create: 'Crear',
  CreateANewFee: 'Crear una nueva tarifa',
  IdAndCreatedAtDate: 'ID y fecha de creacion',
  Created: 'Creado',
  CreatedAt: 'Creado en',
  CreateNew: 'Crear nuevo',
  CreateNewCoupon: 'Crear nuevo cupón',
  CreditCardExp: 'Vencimiento de la tarjeta de crédito',
  CreditCardLast4: 'Últimos 4 dígitos de la tarjeta de crédito',
  CreditCard: 'Tarjeta de Crédito',
  Currency: 'Moneda',
  CVC: 'CVC',
  Dashboard: 'Tablero',
  Dates: 'Fechas',
  Day: 'Día',
  days: 'días',
  Deals: 'Ofertas',
  Delete: 'Eliminar',
  Description: 'Descripción',
  DestinationType: 'Tipo de destino',
  DestinationCode: 'Código del destino',
  Destination: 'Destino',
  DestinationRequired: 'Destino requerido',
  Details: 'Detalles',
  DiscountAmount: 'Monto del descuento',
  DiscountType: 'Tipo de descuento',
  distance: 'distancia',
  Done: 'Listo',
  Download: 'Descargar',
  DoYouWantToDeletedTheFee: '¿Quieres eliminar la tarifa?',
  DoYouWantToDeletedThisCoupon: '¿Quieres eliminar este cupón?',
  DoYouWantToDeletedThisElement: '¿Quieres eliminar este elemento?',
  driving: 'conduciendo',
  DurationInDays: 'Duración en días',
  Edit: 'Editar',
  EditFee: 'Editar tarifa',
  email: 'correo electrónico',
  Email: 'Correo electrónico',
  EmailIsRequired: 'El correo electrónico es requerido',
  EndAt: 'Termina en',
  EndDate: 'Fecha de finalización',
  EnterTheAgesForTheChildren: 'Ingresa las edades de los niños',
  EnterYourCoupon: 'Ingresa tu cupón',
  Error: 'Error',
  Expense: 'Gasto',
  Expiration: 'Vencimiento',
  ExploreEnjoy: '¡Explora y disfruta!',
  ExploreOurProperties: 'Explora nuestras propiedades',
  FailedCreatingCoupon: 'Error al crear el cupón',
  FailedCreatingReferral: 'Error al crear la referencia',
  FailedToGetOrders: 'Error al obtener las órdenes',
  FailedToLoadTheCoupons: 'Error al cargar los cupones',
  FailedToLoad: 'Error al cargar',
  FailedToLoadTheFees: 'Error al cargar las tarifas',
  FailedToLoadTheLogs: 'Error al cargar los registros',
  FailedToLoadTheMessages: 'Error al cargar los mensajes',
  FailedToLoadTheOrderReferrals: 'Error al cargar las referencias de órdenes',
  FailedToLoadTheReferrals: 'Error al cargar las referencias',
  FailedToRefund: 'Error al reembolsar',
  FailedUpdatingReferral: 'Error al actualizar la referencia',
  Fees: 'Tarifas',
  FeeType: 'Tipo de tarifa',
  FilterBy: 'Filtrar por',
  Filters: 'Filtros',
  FindAnotherDate: 'Buscar otra fecha',
  FindAnotherDateInThisProperty: 'Buscar otra fecha en esta propiedad',
  FirstName: 'Nombre',
  Fixed: 'Fijo',
  FollowUs: 'Síguenos',
  ForDirectAssistanceCallOurOfficeAt: 'Para asistencia directa llama a nuestra oficina al',
  Found1Property: 'Encontrada 1 propiedad',
  FreeCancelation: 'Cancelación gratuita',
  from: 'del',
  FullName: 'Nombre completo',
  FullNameIsTooLong: 'El nombre completo es demasiado largo',
  FullNameIsTooShort: 'El nombre completo es demasiado corto',
  Generate: 'Generar',
  GoodNewsPriceHasDecreasedMsg:
    '"¡Buenas noticias! El precio total de tu reserva ha disminuido debido a la fijación dinámica de precios. Se te cobrará una tarifa más baja."',
  GoToTheHomePage: 'Ir a la página de inicio',
  GuaranteedPrices: 'Precios garantizados',
  Guest: 'Huésped',
  Guests: 'Huéspedes',
  GuestsAndRooms: 'Huéspedes y habitaciones',
  Hosts: 'Anfitriones',
  hotel: 'hotel',
  Hotel: 'Hotel',
  HotelCode: 'Código de hotel',
  HotelsCode: 'Código de hoteles',
  HotelInfo: 'Información del hotel',
  HotelObservations: 'Observaciones del hotel',
  HotelRequest: 'Solicitud de hotel',
  Hotels: 'Hoteles',
  HowToGo: 'Cómo ir',
  IfYouReLookingAtAFamilyResort:
    'Si estás buscando un resort familiar o un paquete de vacaciones, nuestros consultores te guiarán con su profundo conocimiento de los lugares.',
  IfYouWereAssistedByAnAgentProvideTheirName: 'Si te ayudó un agente, por favor proporciona su nombre aquí.',
  IHaveACoupon: 'Tengo un cupón',
  Images: 'Imágenes',
  ImportantInfo: 'Información importante',
  ImportantNotice: 'Aviso importante',
  Inactive: 'Inactivo',
  IndicatesRequired: 'Indica requerido',
  Install: 'Instalar',
  Installing: 'Instalando',
  InstallTheApp: 'Instalar la aplicación',
  InternalReservationId: 'ID de reserva interna',
  Invalid: 'Inválido',
  invalidCard: 'tarjeta inválida',
  InvalidEmail: 'Correo electrónico inválido',
  InvalidPhoneNumber: 'Número de teléfono inválido',
  IsAPromotionCoupon: 'Es un cupón de promoción',
  KevinEmbarkedOnHisJourneyInTheTravelIndustry:
    '"Kevin comenzó su viaje en la industria de los viajes hace casi veinticinco años. Su trayectoria profesional comenzó con un encuentro fortuito con el prestigioso club de vacaciones mundiales italiano, I Viaggi del Ventaglio. Desde sus humildes comienzos como guía de viajes y entretenedor turístico, Kevin ascendió rápidamente en las filas, asumiendo roles clave como líder de equipo, especialista en gestión de viajes y desarrollador de turistas grupales en un período de tiempo notablemente corto. Su excepcional capacidad para enfrentar desafíos de frente, junto con su talento innato para fomentar fuertes conexiones con sus clientes, lo impulsó a destacarse en su campo. Por encima de todo, fue su pasión inquebrantable por los viajes lo que encendió una chispa dentro de él, alimentando su dedicación a esta cautivadora industria."',
  Lang: 'Idioma',
  Last7Days: 'Últimos 7 días',
  LastMonth: 'Último mes',
  LastName: 'Apellido',
  LastWeek: 'Última semana',
  Level: 'Nivel',
  Limit: 'Límite',
  Link: 'Enlace',
  listOfDaysOfTheWeek: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
  listOfMonths: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
  LoadingPolicies: 'Cargando Políticas',
  LocallyOwned: 'De propiedad local',
  Location: 'Ubicación',
  Login: 'Iniciar sesión',
  Signin: 'Iniciar sesión',
  Signup: 'Crear cuenta',
  LoginFailed: '¡Inicio de sesión fallido!',
  SigninFailed: '¡Inicio de sesión fallido!',
  SignupFailed: 'Registro de cuenta fallido!',
  Logout: 'Cerrar sesión',
  Logs: 'Registros',
  LookingAvailabilitiesInTheSelectedDates: 'Buscando disponibilidad en las fechas seleccionadas',
  LowHotelRates: 'Tarifas de hotel bajas',
  Manager: 'Gerente',
  MarketingDirector: 'Director de Marketing',
  Markup: 'Markup',
  Message: 'Mensaje',
  Messages: 'Mensajes',
  MessageSent: 'Mensaje enviado',
  MoreServices: 'Más servicios',
  Name: 'Nombre',
  NameLastName: 'Nombre y Apellido',
  NEED_HELP: '¿NECESITAS AYUDA?',
  nights: 'noches',
  Nights: 'Noches',
  nNAvailability: 'No disponible',
  No: 'No',
  NoLocations: 'Sin ubicaciones',
  None: 'Ninguno',
  NoPropertiesFound: 'No se encontraron propiedades',
  NoReimbursable: 'No reembolsable',
  NoResults: 'Sin resultados',
  SearchingAvailabilitiesInTheSelectedWeek: 'Buscando disponibilidades en la semana seleccionada',
  NothingToSeeHere: '¡Aquí no hay nada que ver!',
  NotVisible: 'No visible',
  Ok: 'Ok',
  OnBehalfOf: 'En nombre de',
  OpenSettings: 'Abrir ajustes',
  Order: 'Orden',
  OrderHaveBeenRefunded: 'La orden ha sido reembolsada',
  OrderId: 'ID de orden',
  OrderReferrals: 'Referencias de orden',
  Orders: 'Órdenes',
  OurMission: 'Nuestra misión',
  OurServices: 'Nuestros servicios',
  OurStory: 'Nuestra historia',
  OurUtmostGoalIsToMeticulouslyPlan:
    'Nuestro objetivo supremo es planificar y diseñar la mejor experiencia de viaje para usted y su familia, asegurándonos de descubrir las opciones de viaje más excepcionales disponibles. Además, nos comprometemos a asegurar que cada persona disfrute de sus vacaciones con total facilidad. Por lo tanto, ¡si tiene preferencias o destinos de viaje específicos en mente, o si está celebrando una ocasión especial, infórmenos! Ya sea que sea un intrépido viajero solitario, una pareja aventurera o un grupo animado de familiares, amigos o compañeros de trabajo, Bellitours se dedica apasionadamente a empoderarlo para explorar el mundo y abrazar posibilidades ilimitadas!',
  Paid: 'Pagado',
  SetAsPaid: 'Marcar como pagado',
  SetAsUnpaid: 'Marcar como no pagado',
  PartneredWithLeadingHotelChains:
    'Asociados con cadenas hoteleras líderes y propiedades de alquiler, ofrecemos una de las mejores tarifas de hoteles en línea. Solo verifica nuestros precios. Te sorprenderás',
  Password: 'Contraseña',
  ConfirmPassword: 'Confirm contraseña',
  Payed: 'Pagado',
  Payload: 'Carga útil',
  PaymentDetails: 'Detalles del pago',
  PaymentInformation: 'Información de pago',
  PaymentType: 'Tipo de pago',
  Pending: 'Pendiente',
  PendingToPaid: 'Pendiente de pago',
  PendingToPay: 'Pendiente de pagar',
  Percentage: 'Porcentaje',
  perNight: 'por noche',
  Phone: 'Teléfono',
  PhoneNumber: 'Número de teléfono',
  Photos: 'Fotos',
  PleaseCompleteTheRequiredInformation: 'Por favor, completa la información requerida',
  Policies: 'Políticas',
  Policy: 'Política',
  PopularAmenities: 'Servicios populares',
  PostalCode: 'Código postal',
  PreReserved: 'Pre-reservado',
  Price: 'Precio',
  PriceGreaterThan: 'Precio mayor que',
  PrivacyCookiesPolicy: 'Política de privacidad y cookies',
  PrivacyStatement: 'Declaración de privacidad',
  ProceedToRefund: 'Proceder al reembolso',
  Profile: 'Perfil',
  Profit: 'Ganancia',
  Promotion: 'Promoción',
  PromotionCouponText: 'Texto del cupón de promoción',
  PromotionText: 'Texto de promoción',
  PropertyName: 'Nombre de la propiedad',
  PropertyPrice: 'Precio de la propiedad',
  PropertyRating: 'Calificación de la propiedad',
  PropertyType: 'Tipo de propiedad',
  receipt: 'recibo',
  RecommendedAllInclusiveHotelsForYouToConsider: 'Hoteles todo incluido recomendados para que consideres',
  Referral: 'Referencia',
  ReferralCode: 'Código de referencia',
  ReferralCreated: 'Referencia creada',
  Referrals: 'Referencias',
  ReferralUpdated: 'Referencia actualizada',
  ReferredBy: 'Referido por',
  ReferringAgent: 'Agente referente',
  Refunding: 'Reembolso',
  RefundOrder: 'Reembolsar orden',
  Reimbursable: 'Reembolsable',
  Remove: 'Eliminar',
  RemoveCoupon: 'Eliminar cupón',
  RemoveImage: 'Eliminar imagen',
  RemoveRoom: 'Eliminar habitación',
  RequestToTheHotel: 'Solicitud al hotel',
  Required: 'Requerido',
  ReservationConfirmed: '¡Reserva confirmada!',
  ReservationInfo: 'Información de la reserva',
  ReservationNo: 'Nº de reserva',
  Reservations: 'Reservas',
  ReservePrice: 'Precio de reserva',
  Room: 'Habitación',
  rooms: 'habitaciones',
  SalesManager: 'Gerente de ventas',
  Save: 'Guardar',
  SaveOnYourNextStay: 'Ahorra en tu próxima estadía',
  Saving: 'Guardando',
  Search: 'Buscar',
  SearchALocation: 'Buscar una ubicación',
  SearchADestinationsAndHotels: 'Buscar destinos y hoteles',
  SearchDestinations: 'Buscar destinos',
  SearchHotels: 'Buscar hotels',
  searching: 'buscando',
  Searching: 'Buscando',
  SearchMore: 'Buscar mas',
  SearchMoreProperties: 'Buscar más propiedades',
  Select: 'Seleccionar',
  SelectACountry: 'Selecciona un país',
  SelectDateAndTime: 'Seleccionar fecha y hora',
  SelectGuests: 'Seleccionar invitados',
  SellPrice: 'Precio de venta',
  SendMessage: 'Enviar mensaje',
  ServingYouSince2018: 'Sirviéndote desde 2018',
  Settings: 'Ajustes',
  ShowAll: 'Mostrar todo',
  ShowInMap: 'Mostrar en el mapa',
  ShowLess: 'Mostrar menos',
  SorryAnUnexpectedErrorHasOccurred: 'Lo sentimos, ha ocurrido un error inesperado.',
  Sort: 'Ordenar',
  SortBy: 'Ordenar por',
  SpecialRequestForTheHotel: 'Solicitud especial para el hotel',
  stars: 'estrellas',
  StartAt: 'Comienza a las',
  StartDate: 'Fecha de inicio',
  StartPlanningWithUs: 'Empieza a planificar con nosotros',
  Status: 'Estado',
  StripeCharge: 'Cargo Stripe',
  StripeCurrency: 'Moneda Stripe',
  StripeCustomerId: 'ID de cliente de Stripe',
  StripeReceiptUrl: 'URL del recibo de Stripe',
  StripeRefund: 'Reembolso Stripe',
  StripeStatus: 'Estado Stripe',
  Subject: 'Asunto',
  Subscribe: 'Suscribirse',
  Subtotal: 'Subtotal',
  Support: 'Soporte',
  tel: 'tel',
  TermsOfService: 'Términos de servicio',
  Text: 'Texto',
  TheCouponDoesNotApplyPleaseRemoveTheCouponBeforeProceed: 'El cupón no aplica. Por favor, elimina el cupón antes de continuar',
  TheHotelHasProvidedTheFollowingImportantInfo:
    'El hotel ha proporcionado la siguiente información importante que debes revisar y aceptar antes de completar tu reserva:',
  ThereIsNoAvailabilityInTheSelectedDates: 'No hay disponibilidad en las fechas seleccionadas',
  ThereWasAnErrorSendingTheMessagePleaseTryAgain: 'Hubo un error al enviar el mensaje. Por favor, inténtalo de nuevo',
  ThereWasAnErrorWithYourCreditCard: 'Hubo un error con tu tarjeta de crédito, por favor inténtalo de nuevo',
  ThisFieldIsRequired: 'Este campo es obligatorio',
  ThisMonth: 'Este mes',
  ThisWebsiteUsesCookiesToEnhanceUserExperience:
    'Este sitio web utiliza cookies para mejorar la experiencia del usuario y para analizar el rendimiento y el tráfico en nuestro sitio web. También compartimos información sobre su uso de nuestro sitio con nuestros socios de redes sociales, publicidad y análisis.',
  ThisWeek: 'Esta semana',
  Title: 'Título',
  Today: 'Hoy',
  TopHotels: 'Top hoteles',
  total: 'total',
  Total: 'Total',
  TotalPayed: 'Total pagado',
  TotalPrice: 'Precio total',
  Tours: 'Tours',
  ToursDestinations: 'Destinos de tours',
  TravelAdvisor: 'Asesor de viajes',
  TravelAgent: 'Agente de viajes',
  TravelInsurance: 'Seguro de viaje',
  TrendingDestinations: 'Destinos populares',
  Type: 'Tipo',
  TypeOfProfit: 'Tipo de ganancia',
  UnexpectedError: 'Error inesperado',
  Unit: 'Unidad',
  UnlockExclusiveTravelInspirationForYourNextGetawaySubscribeToOurNewsletter:
    'Desbloquea inspiración exclusiva de viaje para tu próxima escapada suscribiéndote a nuestro boletín.',
  Unpaid: 'No pagado',
  Unrated: 'Sin calificación',
  Unselect: 'Deseleccionar',
  Update: 'Actualizar',
  UpdatedAt: 'Actualizado el',
  UpsSomethingWasWrong: 'Ups. Algo salió mal.',
  Used: 'Usado',
  VacationPackages: 'Paquetes de vacaciones',
  Value: 'Valor',
  View: 'Ver',
  viewLess: 'ver menos',
  viewMore: 'ver más',
  Visible: 'Visible',
  walking: 'caminando',
  WeAreContinuouslyDevelopingOurSystem:
    'Estamos desarrollando continuamente nuestro sistema, nuestras habilidades y conocimientos de viaje para brindar el mejor servicio al cliente.',
  Website: 'Sitio web',
  WeDLoveToHearFromYou: 'Nos encantaría saber de ti.',
  WeHaveSpecialToursDestinationsAndPackages:
    'Tenemos destinos especiales de tours y paquetes listos para ti. Adaptados especialmente para nuestros clientes, creamos rutas y viajes especiales',
  WelcomeYouVeBeenReferredToOurWebsiteBy: '"Bienvenido" Has sido referido a nuestro sitio web por',
  WeLlGiveYouThePriceYouWillEnjoyTheTime: 'Te daremos el precio. Disfrutarás el tiempo',
  Yes: 'Sí',
  Yesterday: 'Ayer',
  YouCanUseBellitoursDirectlyInYourMobileForABetterExperience:
    'Puedes usar Bellitours directamente en tu móvil para una mejor experiencia.',
  YourContactInfo: 'Tu información de contacto',
  YourMessageHaveBeenSent: 'Tu mensaje ha sido enviado!',
  YourPaymentInfo: 'Tu información de pago',
  Client: 'Cliente',
  Customer: 'Cliente',
  Discount: 'Descuento',
  SignOut: 'Cerrar sesión',
  orLoginWithEmailAndPassword: 'o iniciar sesión con email y contraseña',
  BackToHome: 'Volver al inicio',
  ForgotPassword: '¿Olvidó la contraseña?',
  DontHaveAnAccount: '¿No tiene una cuenta?',
  RecoverPassword: 'Recuperar el password',
  AlreadyHaveAnAccount: '¿Ya tiene una cuenta?',
  PasswordsDonTMatch: 'Las contraseñas no coinciden',
  // transalte this
  ThankYouForSigningUp: 'Gracias por registrarte',
  ToCompleteYourRegistrationCheckEmail:
    'Para completar tu registro, por favor revisa tu correo electrónico en busca de un enlace de verificación. Haz clic en el enlace para confirmar tu dirección de correo electrónico y activar tu cuenta. Si no ves el correo en tu bandeja de entrada, por favor revisa la carpeta de spam o correo no deseado.',
  VerifyingEmail: 'Verificando correo electrónico',
  InvalidLink: 'Enlace inválido',
  LinkExpired: 'El enlace ha caducado',
  EmailValidatedSuccessfully: 'El correo electrónico ha sido validado exitosamente',
  TheVerificationLinkYouUsedIsInvalid:
    'Lo sentimos, pero el enlace de verificación que usaste es inválido. Por favor, revisa el enlace en tu correo electrónico y vuelve a intentarlo, o solicita un nuevo enlace de verificación.',
  TheVerificationLinkHasExpired:
    'El enlace de verificación ha caducado. Por favor, solicita un nuevo correo de verificación para confirmar tu dirección.',
  YourAccountHasAlreadyBeenVerified: 'Tu cuenta ya ha sido verificada. Ahora puedes iniciar sesión usando tus credenciales.',
  YourEmailHasBeenSuccessfullyVerified:
    '¡Gracias! Tu correo electrónico ha sido verificado exitosamente. Ahora puedes iniciar sesión en tu cuenta.',
  YourEmailHasNotBeenVerified: '¿Tu correo electrónico no ha sido verificado?',
  ResendEmail: 'Reenviar correo electrónico',
  ResendVerificationEmail: 'Reenviar correo de verificación',
  FailedToResendEmailPleaseTryAgain: 'No se pudo reenviar el correo electrónico, por favor intenta de nuevo.',
  VerificationEmailHasBeenResend: 'El correo de verificación ha sido reenviado',
  AVerificationEmailWillBeSendToYourEmailAddress:
    'Se enviará un correo de verificación a tu dirección de correo electrónico si el correo aún está pendiente de verificación. Por favor revisa tu bandeja de entrada (y tu carpeta de spam/correo no deseado) en busca del correo y haz clic en el enlace para verificar tu cuenta.',
  EmailHasBeenSent: 'El correo electrónico ha sido enviado',
  EmailToRecoverPassHasBeenSend:
    'Se ha enviado un correo para recuperar tu contraseña a la dirección de correo especificada. Por favor revisa tu bandeja de entrada (y tu carpeta de spam/correo no deseado) en busca del correo, y haz clic en el enlace para recuperar tu contraseña.',
  RecoverPass: 'Recuperar contraseña',
  UpdateYourPassword: 'Actualiza tu contraseña',
  PasswordSuccessfullyChanged: 'Contraseña cambiada exitosamente',
  YourPasswordHasBeenUpdatedSuccessfully:
    'Tu contraseña ha sido actualizada exitosamente. Por favor utiliza tu nueva contraseña la próxima vez que inicies sesión. Si no solicitaste este cambio, por favor contacta a nuestro equipo de soporte de inmediato.',
  UpdatePasswordFailed: '¡Error al actualizar la contraseña!',
  AnUnexpectedErrorOccurredWhileUpdatingPassword:
    'Ocurrió un error inesperado al intentar actualizar tu contraseña. Por favor intenta de nuevo más tarde. Si el problema persiste, por favor contacta a nuestro equipo de soporte.',
  ThePasswordResetLinkIsInValidOrExpired:
    'Lo sentimos, el enlace de restablecimiento de contraseña es inválido o ha caducado. Por favor solicita un nuevo enlace de restablecimiento de contraseña y vuelve a intentarlo.',
  PasswordUpdatedSuccessfully:
    'Tu contraseña ha sido actualizada exitosamente. Ahora puedes iniciar sesión con tu nueva contraseña. Si no solicitaste este cambio, por favor contacta a nuestro equipo de soporte de inmediato.',
  YouDontHaveAccessToTheRequestedPage: 'No tienes permisos para acceder a la página solicitada',
  Role: 'Rol',
  AuthProviders: 'Proveedores de autenticación',
  Verified: 'Verificado',
  Users: 'Usuarios',
  User: 'Usuario',
  Hi: 'Hola',
  Favorites: 'Favoritos',
  ProvideYourPersonalDetails: 'Provea sus datos personales',
  CheckYourFavoriteProperties: 'Chequeat tus propiedades favorias',
  SeeYourReservations: 'Ver reservaciones',
  Security: 'Seguridad',
  CheckYouSecurityAndSettings: 'Chequear la seguridad y otros ajusters',
  NotProvided: 'No provisto',
  Gender: 'Género',
  Bio: 'Bio',
  DateOfBirth: 'Fecha de nacimiento',
  ShowPricesBy: 'Mostrar precios agrupador por',
  SearchPricesBy: 'Buscar precios por rango de',
  ShowAdvancedSearch: 'Mostrar búsquedas flexibles',
  HideAdvancedSearch: 'Ocultar búsqueda flexibles',
  YourFavoriteProperties: 'Tus hoteles favoritos',
  NoDiscount: 'Sin descuento',
  Commission: 'Comisión',
  GetGender: (gender: Gender) =>
    ({
      [Gender.Female]: 'Femenino',
      [Gender.Male]: 'Masculino',
      [Gender.Unspecified]: 'No especificado',
      [Gender.Undisclosed]: 'No revelado',
    }[gender]),
  RequireAtLeastNChars: (n: number) => `Require at least ${n} characters`,
  acceptTerms: (props: { privacyStatementLink: ReactNode; termsOfServiceLink: ReactNode }) => (
    <>
      Al hacer clic en este botón, confirmo que he leído {props.privacyStatementLink} y el Consejo de Viaje del Gobierno, y he leído y
      acepto los {props.termsOfServiceLink}.
    </>
  ),
  andNChildren: (amount: number) => `y ${amount} niños`,
  AnErrorHasOccurred: (message: string) => `¡Se ha producido un error! ${message || ''}`,
  AnErrorHasOccurredTryAgain: (message: string) => `¡Se ha producido un error! ${message || 'Por favor, inténtalo de nuevo.'}`,
  AnUnexpectedErrorHasOccurredPleaseReloadThePage: (message: string) =>
    `Se ha producido un error inesperado. Por favor, recarga la página. ${message || ''}`,
  DoYouWantToActivateDeactivateCoupon: (props: { activate: boolean }) =>
    `¿Quieres ${props.activate ? 'activar' : 'desactivar'} este cupón?`,
  DoYouWantToUpdateThisOrderReferralAsPaidUnpaid: (props: { paid: boolean }) =>
    `¿Quieres actualizar esta referencia de orden como ${props.paid ? 'pagada' : 'no pagada'}?`,
  foundNProperties: (amount: number) => `Encontradas ${amount} propiedades`,
  goodNewsPriceHasDecreasedToNewTotalMsg: (total: number) => (
    <>
      El precio total de tu reserva ha aumentado debido a la fijación dinámica de precios. El nuevo total es <b>{total}</b>. Puedes elegir
      continuar con el monto actualizado o cancelar tu reserva. Por favor, confirma tu elección para continuar o cancelar.
    </>
  ),
  hotelConsiderChildrenFrom: (props: { from: ReactNode; to: ReactNode }) => (
    <>
      El hotel considera niños de {props.from} a {props.to} años de edad.
    </>
  ),
  MinLengthIs: (len: number) => `La longitud mínima es ${len}`,
  MinValueIs: (value: number) => `El valor mínimo es ${value}`,
  protectionOfPersonalInfoMsg: (
    <>
      Nos aseguramos de proteger tu información personal mediante transmisión segura y almacenamiento encriptado.
      <br /> Si bien los pagos generalmente se procesan en EE.UU., algunos proveedores de viajes (como hoteles o aerolíneas) pueden procesar
      tu pago fuera de EE.UU., lo que podría resultar en que tu emisor de tarjeta aplique una tarifa por transacción extranjera.
    </>
  ),
  selectNRoomForPax: (props: { room: any; pax: any }) => (
    <>
      Selecciona {props.room} habitación para {props.pax}
    </>
  ),
  ValueNeedsToBeGreaterThanN: (number: number) => `El valor debe ser mayor que ${number}`,
};
